import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Tabs,
  Tab,
} from "@mui/material";
import axios from "axios";
import { AdRequest, AdRequestImagePreviews } from "../models/adRequest";
import ConfirmFinishAdRequestDialog from "../conponents/ConfirmFinishAdRequestDialog";
import { getToken } from "../services/authentication";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_API_URL;

const AdminAdRequests: React.FC = () => {
  const [adRequests, setAdRequests] = useState<AdRequest[]>([]);
  const [imagePreviews, setImagePreviews] = useState<AdRequestImagePreviews>(
    {}
  );
  const fileInputRefs = useRef<{ [key: number]: HTMLInputElement }>({});
  const [selectedFiles, setSelectedFiles] = useState<{ [key: number]: File[] }>(
    {}
  );
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [currentAdRequest, setCurrentAdRequest] = useState<AdRequest | null>(
    null
  );
  const [tabIndex, setTabIndex] = useState(0); // 0 for active requests, 1 for finished requests
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAdRequests = async () => {
      const finished = tabIndex === 1;
      try {
        const response = await axios.get<AdRequest[]>(
          `${API_URL}/ad/getAllRequests`,
          {
            params: { finished },
          }
        );
        setAdRequests(response.data);
      } catch (error) {
        console.error("Error fetching ad requests:", error);
      }
    };
    fetchAdRequests();
  }, [tabIndex]);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    adRequestId: number
  ) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      const newPreview = URL.createObjectURL(file);

      setImagePreviews((prev) => ({
        ...prev,
        [adRequestId]: [...(prev[adRequestId] || []), newPreview].slice(0, 3),
      }));

      setSelectedFiles((prev) => ({
        ...prev,
        [adRequestId]: [...(prev[adRequestId] || []), file].slice(0, 3),
      }));
    }
  };

  const handleRemovePreview = (adRequestId: number, imgIndex: number) => {
    setImagePreviews((prev) => ({
      ...prev,
      [adRequestId]:
        prev[adRequestId]?.filter((_, index) => index !== imgIndex) || [],
    }));
    setSelectedFiles((prev) => ({
      ...prev,
      [adRequestId]:
        prev[adRequestId]?.filter((_, index) => index !== imgIndex) || [],
    }));
  };

  const handleSubmit = async (adRequest: AdRequest) => {
    const files = selectedFiles[adRequest.id];
    if (!files || files.length === 0) {
      alert("Please select at least one file for the ad request.");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => formData.append("media", file));
    formData.append("companyId", adRequest.companyId.toString());
    formData.append("title", adRequest.title);

    try {
      const token = getToken();
      await axios.post(
        `${API_URL}/company/uploadMedia`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSelectedFiles((prev) => ({ ...prev, [adRequest.id]: [] }));
      setImagePreviews((prev) => ({ ...prev, [adRequest.id]: [] }));
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleFinish = (adRequest: AdRequest) => {
    setCurrentAdRequest(adRequest);
    setIsConfirmDialogOpen(true);
  };

  const onConfirmFinish = async () => {
    if (currentAdRequest && currentAdRequest.id) {
      try {
        await axios.put(
          `${API_URL}/ad/finishAdRequest/${currentAdRequest.id}`
        );
        setIsConfirmDialogOpen(false);
      } catch (error) {
        console.error("Error finishing ad request:", error);
      }
    }
  };

  return (
    <Container component="main" sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h4" sx={{ color: "white", mb: 3 }}>
        {t("adminAdRequests_title")}
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={(event, newValue) => setTabIndex(newValue)}
        aria-label={t("adminAdRequests_activeRequests")}
      >
        <Tab label={t("adminAdRequests_activeRequests")} />
        <Tab label={t("adminAdRequests_finishedRequests")} />
      </Tabs>
      <Grid container spacing={2}>
        {adRequests.map((adRequest) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={adRequest.id}>
            <Card
              variant="outlined"
              sx={{ borderRadius: "16px", background: "black", mb: 3 }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ color: "text.secondary" }}
                >
                  {adRequest.title}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "white" }}>
                  {t("adminAdRequests_companyID")} {adRequest.companyId}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: "white" }}>
                  {adRequest.details}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {imagePreviews[adRequest.id]?.map((url, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={url}
                      alt={`Preview ${imgIndex}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleRemovePreview(adRequest.id, imgIndex)
                      }
                    />
                  ))}
                </div>
                <Typography variant="body1" sx={{ color: "white" }}>
                  {t("adminAdRequests_quantity")} {adRequest.outputs}
                </Typography>
              </CardContent>
              <Grid
                container
                spacing={1}
                style={{ padding: "16px" }}
                justifyContent="space-between"
              >
                {(!imagePreviews[adRequest.id] ||
                  imagePreviews[adRequest.id].length < 3) && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          fileInputRefs.current[adRequest.id]?.click()
                        }
                      >
                        {t("adminAdRequests_upload")}
                      </Button>
                      <input
                        type="file"
                        multiple
                        onChange={(event) =>
                          handleFileChange(event, adRequest.id)
                        }
                        style={{ display: "none" }}
                        ref={(el) =>
                        (fileInputRefs.current[adRequest.id] =
                          el as HTMLInputElement)
                        }
                      />
                    </Grid>
                  )}
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(adRequest)}
                  >
                    {t("adminAdRequests_submit")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleFinish(adRequest)}
                  >
                    {t("adminAdRequests_finish")}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ConfirmFinishAdRequestDialog
        open={isConfirmDialogOpen}
        title={t("adminAdRequests_confirmTitle")}
        content={t("adminAdRequests_confirmContent")}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={onConfirmFinish}
      />
    </Container>
  );
};

export default AdminAdRequests;
