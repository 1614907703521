import { useState } from 'react';
import { Grid, Typography, IconButton, Paper, Container } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TikTokIcon from '@mui/icons-material/MusicNote'; // Assuming TikTok is represented by a music note icon
import TwitterIcon from '@mui/icons-material/Twitter';
import DynamicExpandableSocialSection from '../socialsExpandable/SocialsExpandableSection';
import { useTranslation } from 'react-i18next';

const SocialMediaComponent = () => {
    const [activeSocialMedia, setActiveSocialMedia] = useState('');
    const { t } = useTranslation();

    const handleSocialMediaClick = (socialMediaName: string) => {
        setActiveSocialMedia(current => current === socialMediaName ? '' : socialMediaName);
    };

    return (
        <Container component="main" sx={{ mt: 2, mb: 2 }}>
            <Paper sx={{ p: 2, backgroundColor: 'black' }}>
                <Typography variant="h6" color="primary" gutterBottom>
                    {t('socialMediaComponent_title')}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: 'grey', mb: 2 }}>
                    {t('socialMediaComponent_description')}
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    <IconButton onClick={() => handleSocialMediaClick('Instagram')} color="primary">
                        <InstagramIcon sx={{ fontSize: '2rem' }} />
                    </IconButton>
                    <IconButton onClick={() => handleSocialMediaClick('Facebook')} color="primary">
                        <FacebookIcon sx={{ fontSize: '2rem' }} />
                    </IconButton>
                    <IconButton onClick={() => handleSocialMediaClick('TikTok')} color="primary">
                        <TikTokIcon sx={{ fontSize: '2rem' }} />
                    </IconButton>
                    <IconButton onClick={() => handleSocialMediaClick('Twitter')} color="primary">
                        <TwitterIcon sx={{ fontSize: '2rem' }} />
                    </IconButton>
                </Grid>
                <DynamicExpandableSocialSection activeSocialMedia={activeSocialMedia} />
            </Paper>
        </Container>
    );
};

export default SocialMediaComponent;
