import axios from "axios";
import { parseJwt } from "../helpers/common";
import { LoginResponse } from "../models/backend";

const API_URL = process.env.REACT_APP_API_URL;

export async function login(email: string, password: string): Promise<[boolean, string]> {

  const responseRaw = await axios.post(API_URL + "/users/login", { email, password });
  const response: LoginResponse = responseRaw.data;

  if (response.token) {
    localStorage.setItem("wistaa_data", JSON.stringify(response));
    return [true, ''];
  }

  return [false, 'Login failed: No token received'];
}

export async function register(email: string, password: string, companyName: string, selectedCountry: string, regionId: number): Promise<[boolean, string, number | null]> {
  try {
    const response = await axios.post(API_URL + "/users/register", { email, password, companyName, regionId });
    const responseData = response.data;

    if (response.status === 201)
      return [true, 'Registration successful', responseData.userId];

    return [false, 'Registration failed', null];
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || 'Registration request failed';
    return [false, errorMessage, null];
  }
}

export async function forgotPassword(email: string): Promise<[boolean, string]> {
  try {
    const response = await axios.post(API_URL + "/users/forgot-password", { email });

    if (response.status === 200) {
      return [true, 'Password reset email sent successfully'];
    }

    return [false, 'Failed to send password reset email'];
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || 'Password reset request failed';
    return [false, errorMessage];
  }
}

export async function verifyUser(token: string): Promise<[boolean, string]> {
  try {
    const response = await axios.post(API_URL + "/users/verify-user", { token });

    if (response.status === 500)
      return [false, response.data.error];
    return [true, ''];
  } catch (error: any) {
    return [false, error?.response?.data?.error];
  }
}

export async function resetPassword(password: string, token: string): Promise<[boolean, string]> {
  try {
    const response = await axios.post(API_URL + "/users/reset-password", { password, token });

    if (response.status === 500)
      return [false, response.data.error];
    return [true, ''];
  } catch (error: any) {
    return [false, error?.response?.data?.error];
  }
}

export function logout() {
  localStorage.removeItem("wistaa_data");
  localStorage.removeItem("wistaa_activeCompany");
  localStorage.removeItem("admin_wistaa_data");
};

export const isAdmin = (): boolean => {
  const loginDataStr = localStorage.getItem("wistaa_data");
  if (loginDataStr) {
    const loginData: LoginResponse = JSON.parse(loginDataStr);
    const token = loginData.token;
    const decoded = parseJwt(token);
    return decoded.roleId === 1;
  }
  return false;
};



export const isAuthenticated = (): boolean => {
  const loginDataStr = localStorage.getItem("wistaa_data");
  if (loginDataStr) {
    const loginData: LoginResponse = JSON.parse(loginDataStr);
    const token = loginData.token;
    const decoded = parseJwt(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  }
  return false;
};

export function getToken() {
  const loginDataStr = localStorage.getItem("wistaa_data");
  if (loginDataStr) {
    const loginData = JSON.parse(loginDataStr);
    return loginData.token;
  }
  return '';
}

export function saveAdminData() {
  const loginDataStr = localStorage.getItem("wistaa_data");
  if (loginDataStr)
    localStorage.setItem("admin_wistaa_data", loginDataStr);
}

export function stopImpersonating() {
  const adminDataStr = localStorage.getItem("admin_wistaa_data");
  if (adminDataStr) {
    localStorage.setItem("wistaa_data", adminDataStr);
    localStorage.removeItem("admin_wistaa_data");
  }
  window.location.reload();
}

export function isImpersonating(): boolean {
  const adminData = localStorage.getItem("admin_wistaa_data");
  return !!adminData;
}