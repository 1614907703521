import React from 'react';
import UploadPhotosComponent from './UploadPhotosComponent';
import GalleryComponent from './GalleryComponent';
import StatisticsComponent from './StatisticsComponent';
import { User } from '../../models/backend';
import UploadVideosComponent from './UploadVideosComponent';
import RequestAd from './RequestAd';
import SocialMediaComponent from './SocialMediaComponent';

interface DynamicExpandableSectionProps {
    activeComponent: string;
    user: User | null
}

const DynamicExpandableSection: React.FC<DynamicExpandableSectionProps> = ({ activeComponent, user }) => {
    const renderComponent = () => {
        switch (activeComponent) {
            case 'UploadPhotos':
                return <UploadPhotosComponent/>;
            case 'UploadVideos':
                return <UploadVideosComponent/>;
            case 'Gallery':
                return <GalleryComponent />
            case 'Statistics':
                return <StatisticsComponent />
            case 'RequestImageVideo':
                return <RequestAd user ={user} />
            case 'SocialMedia':
                return <SocialMediaComponent/>
            default:
                return null;
        }
    };

    return (
        <div>
            {renderComponent()}
        </div>
    );
};

export default DynamicExpandableSection;
