import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function fetchMediaLinks(companyId: number) {
  try {
    const response = await axios.get(API_URL + "/company/getMedia", {
      params: { companyId: companyId },
    });
    return response.data.data;
  } catch (error: any) {
    console.error("Error: ", error);
  }
}
