import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Backdrop,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import theme from "../../helpers/theme";
import { getToken } from "../../services/authentication";
import { useTranslation } from "react-i18next";
import { getTvLimit } from "../../helpers/common";
import { getActiveCompany } from "../../services/localStorage";

const API_URL = process.env.REACT_APP_API_URL;

const UploadPhotosComponent: React.FC = () => {
  const [imageTitle, setImageTitle] = useState("");
  const [qrCodeLink, setQRCodeLink] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTv, setSelectedTv] = useState<number>(1);
  const [tvCount, setTvCount] = useState<number>(0);
  const [tvIds, setTvIds] = useState<number[]>([]);

  const { t } = useTranslation();

  const formClear = () => {
    setImageTitle("");
    setImageFile(null);
    setImagePreview(null);
  };

  useEffect(() => {
    const fetchTvs = async () => {
      const company = getActiveCompany();
      if (company) {
        const customLimits = company.customLimits;
        const subscriptionPlanId = company.subscriptionPlanId;
        const limit = getTvLimit(subscriptionPlanId, customLimits);
        setTvCount(limit);

        const tvs = company.tvs || [];
        // Sort TV IDs to ensure they are in correct order
        const sortedTvs = tvs.sort((a, b) => a.id - b.id);
        setTvIds(sortedTvs.map((tv) => tv.id));
      }
    };

    fetchTvs();
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const tempImage = new Image();
        tempImage.src = reader.result as string;
        tempImage.onload = () => {
          const width = tempImage.width;
          const height = tempImage.height;
          const aspectRatio = width / height;
          const minResolutionWidth = 1600;
          const widescreenAspectRatio = 16 / 9;

          if (
            aspectRatio.toFixed(2) === widescreenAspectRatio.toFixed(2) &&
            width >= minResolutionWidth
          ) {
            setImagePreview(reader.result as string);
          } else {
            alert(
              "Image must be a widescreen aspect ratio (16:9) and at least 3200 pixels wide."
            );
            setImageFile(null); // Clear the selected file
            setImagePreview(null);
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const handleUpload = async () => {
    if (!imageFile) {
      alert("Please fill in all fields and select an image.");
      return;
    }
    setLoading(true);
    const companyId = getActiveCompany()?.id;
    if (!companyId) {
      setSnackbarMessage("No active company found!");
      setSnackbarOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append("companyId", companyId.toString());
    formData.append("title", imageTitle);
    formData.append("qrCodeLink", qrCodeLink);
    formData.append("tvId", tvIds[selectedTv - 1].toString());
    formData.append("media", imageFile);

    try {
      const token = getToken();
      const response = await axios.post(
        API_URL + "/company/uploadMedia",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const firstUploadResult = response.data.data[0]; // Get the first upload result

      if (firstUploadResult && firstUploadResult.Location) {
        setSnackbarMessage("Image uploaded successfully!");
        setUploadSuccess(true);
      } else {
        setSnackbarMessage("Error processing the upload");
        setUploadSuccess(false);
      }

      setLoading(false);
      formClear();
      setSnackbarOpen(true);
    } catch (error: any) {
      let errorMessage = "Error uploading video";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      setSnackbarMessage(errorMessage);
      setUploadSuccess(false);
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => { }}
      >
        <CircularProgress size={64} color="inherit" />
      </Backdrop>
      <Grid container spacing={2} direction="column">
        {/* Upload Image Section */}
        <Grid item xs={12}>
          <Paper
            sx={(theme) => ({
              p: 2,
              backgroundColor: theme.palette.background.default,
            })}
          >
            <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
              {t("uploadPhotosComponent_uploadImage")}
            </Typography>
            <label htmlFor="upload-image-input">
              <input
                accept="image/*"
                id="upload-image-input"
                type="file"
                hidden
                onChange={handleImageChange}
              />
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  width: "100%",
                  overflow: "hidden", // Ensures that the image does not overflow the container
                }}
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Thumbnail"
                    style={{ width: "100%", maxWidth: "500px", height: "auto" }}
                  />
                ) : (
                  <img
                    src="/assets/images/card1/uploadImg.png"
                    alt="Upload"
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                      height: "auto",
                      objectFit: "cover", // Ensures the image covers the container without stretching
                    }}
                  />
                )}
              </Box>
            </label>
          </Paper>
        </Grid>

        {/* Settings Section */}
        <Grid item xs={12}>
          <Paper
            sx={(theme) => ({
              p: 2,
              backgroundColor: theme.palette.background.default,
            })}
          >
            <Typography variant="h6" sx={{ color: "white" }}>
              {t("uploadPhotosComponent_settings")}
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "text.disabled", mb: 2 }}
              >
                {t("uploadPhotosComponent_photoTitle")}
              </Typography>
              <TextField
                placeholder={t("uploadPhotosComponent_enterTitle")}
                fullWidth
                variant="outlined"
                value={imageTitle}
                onChange={(e) => setImageTitle(e.target.value)}
                sx={{
                  backgroundColor: "#3D3D3D",
                  "& .MuiOutlinedInput-input": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "text.disabled", mb: 2 }}
              >
                {t("uploadPhotosComponent_qrCodeLink")}
              </Typography>
              <TextField
                placeholder={t("uploadPhotosComponent_enterQRCode")}
                fullWidth
                variant="outlined"
                value={qrCodeLink}
                onChange={(e) => setQRCodeLink(e.target.value)}
                sx={{
                  backgroundColor: "#3D3D3D",
                  "& .MuiOutlinedInput-input": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "text.disabled", mb: 2 }}
              >
                {t("uploadPhotosComponent_selectTv")}
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                {Array.from({ length: tvCount }, (_, index) => (
                  <Button
                    key={index}
                    variant={
                      selectedTv === index + 1 ? "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => setSelectedTv(index + 1)}
                    sx={{
                      backgroundColor:
                        selectedTv === index + 1
                          ? theme.palette.primary.main
                          : "#3D3D3D",
                      borderColor: "white",
                      color: "white",
                      "&:hover": {
                        backgroundColor:
                          selectedTv === index + 1
                            ? theme.palette.primary.dark
                            : "#5a5a5a",
                      },
                    }}
                  >
                    {index + 1}
                  </Button>
                ))}
              </Box>
            </Box>
          </Paper>
        </Grid>

        {/* Generate Button */}
        <Grid item xs={12}>
          <Button
            onClick={handleUpload}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, height: "50px", borderRadius: "8px" }}
          >
            {t("uploadPhotosComponent_upload")}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={uploadSuccess ? "success" : "error"}
          sx={{
            backgroundColor: "black",
            color: "primary.main",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UploadPhotosComponent;
