import { getSubscriptionPlans } from "../services/localStorage";

export function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

export const convertSubscriptionPriceToCents = (price: number): number => {
  return Math.round(price * 100);
};

export function getTvLimit(
  subscriptionPlanId: number,
  customLimits: number[]
): number {
  const defaultValues = [0, 0, 0, 0, 0];

  // Check if custom limits are provided and not equal to default values
  const isCustomLimitsValid =
    customLimits &&
    customLimits.length > 0 &&
    !customLimits.every((val, index) => val === defaultValues[index]);

  if (isCustomLimitsValid) {
    // Return the TV limit from the custom limits
    return customLimits[0]; // The first element is the TV limit
  }

  const plans = getSubscriptionPlans();
  const plan = plans ? plans.find((p) => p.id === subscriptionPlanId) : null;
  return plan ? plan.defaultLimits.tvLimit : 1;
}
