import React from 'react';
import InstagramComponent from './InstagramComponent'; 


interface DynamicExpandableSocialSectionProps {
    activeSocialMedia: string;
}

const DynamicExpandableSocialSection: React.FC<DynamicExpandableSocialSectionProps> = ({ activeSocialMedia }) => {
    const renderSocialMediaComponent = () => {
        switch (activeSocialMedia) {
            case 'Instagram':
                return <InstagramComponent />;
            case 'Facebook':
                return <InstagramComponent />;
            case 'TikTok':
                return <InstagramComponent />;
            case 'Twitter':
                return <InstagramComponent />;
            default:
                return null; // Or a default message/component
        }
    };

    return (
        <div>
            {renderSocialMediaComponent()}
        </div>
    );
};

export default DynamicExpandableSocialSection;
