import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    content: string;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmFinishAdRequestDialog: React.FC<ConfirmationDialogProps> = ({ open, title, content, onClose, onConfirm }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirmation-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('dialog_no')}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {t('dialog_yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmFinishAdRequestDialog;
