import React from 'react';
import { Dialog, DialogTitle, Typography, Button, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { User } from '../models/backend';
import { logout, stopImpersonating, isImpersonating } from '../services/authentication';
import { useNavigate } from 'react-router-dom';
import SubscriptionCarousel from './SubscriptionCard';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface UserProfileDialogProps {
  open: boolean;
  user: User | null;
  onClose: () => void;
}

const UserProfileDialog: React.FC<UserProfileDialogProps> = ({ open, user, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleLogoutOrStopImpersonating = () => {
    if (isImpersonating()) {
      stopImpersonating();
      window.location.reload();
    } else {
      logout();
      navigate('/login');
    }
  };

  if (!user) return null;

  return (
    <Dialog
      onClose={handleClose}
      open={open && !!user}
      fullWidth={true}
      maxWidth={'lg'}
      PaperProps={{
        sx: {
          border: 'thick solid',
          borderColor: 'primary.main',
          borderRadius: '16px',
          padding: '30px',
          backgroundColor: 'black',
          maxWidth: isMobile ? '100%' : 'initial', // Adjusting maxWidth for mobile
        }
      }}
    >
      <DialogTitle sx={{ color: 'primary.main', textAlign: 'center', position: 'relative' }}>
        {user?.name}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.primary.main, // Use the primary color for the icon
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Typography
        variant="subtitle2"
        sx={{ color: 'grey', textAlign: 'center', mb: 3 }}
      >
        {user?.email}
      </Typography>
      <Button
        fullWidth={true}
        variant="outlined"
        onClick={handleLogoutOrStopImpersonating}
        sx={{
          color: 'white',
          borderColor: 'primary.main',
          mx: 'auto',
          display: 'block',
          mb: isMobile ? 2 : 0, // Adding margin bottom for mobile
        }}
      >
        {isImpersonating() ? "Stop Impersonating" : t('userProfileDialog_logout')}
      </Button>
      <SubscriptionCarousel user={user} />


    </Dialog>
  );
};

export default UserProfileDialog;
