import React, { useState } from 'react';
import { Box, Paper, TextField, Button, Typography, Container, Alert, CircularProgress, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { login, register } from '../services/authentication';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { countryRegions } from '../constants/regions';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginError, setLoginError] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState<number | ''>('');

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoginError('');
        disableUI(true);

        try {
            const [loginSuccess, errorMessage] = await login(email, password);
            if (loginSuccess) navigate('/');
            else setLoginError(errorMessage);
        } catch (error: any) {
            setLoginError(error.response?.data?.error || t('loginPage_errorValidEmail'));
        } finally {
            disableUI(false);
        }
    };

    const disableUI = (disableUI: boolean) => {
        setIsDisabled(disableUI);
    };

    const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoginError('');
        if (!acceptTerms) {
            setLoginError(t('loginPage_acceptTerms'));
            return;
        }

        if (!companyName) {
            setLoginError(t('Please select a company name'));
            return;
        }

        if (password !== confirmPassword) {
            setLoginError(t('loginPage_errorPasswordsMatch'));
            return;
        }

        if (password.length < 8) {
            setLoginError(t('loginPage_errorPasswordLength'));
            return;
        }

        if (!selectedCountry || !selectedRegion) {
            setLoginError("Please select a country and region.");
            return;
        }
        const [registrationSuccess, message] = await register(email, password, companyName, selectedCountry, +selectedRegion);
        if (registrationSuccess) {
           navigate("/prompt-verify")
        } else {
            setLoginError(message);
        }
    };

    const toggleForm = () => {
        setIsRegistering(!isRegistering);
        setLoginError(null);
    };

    return (
        <Container component="main" maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(to bottom, #ffffff, #ff4600)',
        }}>
            <Paper
                elevation={6}
                sx={{
                    maxWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    borderRadius: 2,
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <img src="/assets/images/logo/logo_nobg.svg" alt="Logo" style={{ height: '50px', width: '250px', marginBottom: '20px' }} />
                <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                    {isRegistering ? t('loginPage_registerHeading') : t('loginPage_signInHeading')}
                </Typography>
                {loginError && (
                    <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                        {loginError}
                    </Alert>
                )}
                <Box component="form" onSubmit={isRegistering ? handleRegister : handleLogin} noValidate sx={{ mt: 1, width: '100%' }}>
                    <TextField
                        margin="normal"
                        disabled={isDisabled}
                        required
                        fullWidth
                        id="email"
                        label={t('loginPage_emailLabel')}
                        name="email"
                        autoComplete="email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ input: { color: 'white' }, label: { color: 'white' } }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        disabled={isDisabled}
                        name="password"
                        label={t('loginPage_passwordLabel')}
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        sx={{ input: { color: 'white' }, label: { color: 'white' } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {isRegistering && (
                        <>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label={t('loginPage_confirmPasswordLabel')}
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="confirm-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                sx={{ input: { color: 'white' }, label: { color: 'white' } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle confirm password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="normal"
                                disabled={isDisabled}
                                required
                                fullWidth
                                id="companyName"
                                label={t('loginPage_companyNameLabel')}
                                name="companyname"
                                autoComplete="companyName"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                sx={{ input: { color: 'white' }, label: { color: 'white' } }}
                            />
                            <Typography variant="h6" sx={{ mt: 2, color: 'white' }}>Region</Typography>
                            <FormControl fullWidth margin="normal" disabled={isDisabled} required sx={{ mt: 1 }}>
                                <InputLabel id="country-label">Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    placeholder='Country'
                                    value={selectedCountry}
                                    onChange={(e) => {
                                        setSelectedCountry(e.target.value);
                                        setSelectedRegion('');
                                    }}
                                    fullWidth
                                    style={{ color: 'white' }} // Style for the selected value
                                >
                                    {Object.keys(countryRegions).map((country) => (
                                        <MenuItem key={country} value={country} style={{ color: 'black' }}>
                                            {country}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {selectedCountry && (
                                <FormControl fullWidth margin="normal" disabled={isDisabled} required sx={{ mt: 1 }}>
                                    <InputLabel id="region-label">Region</InputLabel>
                                    <Select
                                        labelId="region-label"
                                        id="region"
                                        value={selectedRegion}
                                        onChange={(e) => setSelectedRegion(Number(e.target.value))}
                                        fullWidth
                                        style={{ color: 'white' }} // Style for the selected value
                                    >
                                        {countryRegions[selectedCountry].map((region) => (
                                            <MenuItem key={region.id} value={region.id} style={{ color: 'black' }}>
                                                {`${region.id}. ${region.title}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            

                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <Checkbox
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                    color="primary"
                                    disabled={isDisabled}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: 'grey',
                                        },
                                    }}
                                />
                                <Typography variant="body2" sx={{ color: 'white' }}>
                                    {t('loginPage_acceptTerms')}
                                </Typography>
                            </Box>
                        </>
                    )}

                    <Button
                        type="submit"
                        fullWidth
                        disabled={isDisabled}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {isRegistering ? t('loginPage_registerButton') : t('loginPage_signInButton')}
                    </Button>
                    {isDisabled && (
                        <CircularProgress
                            size={64}
                            sx={{
                                left: '50%',
                                top: '50%',
                                marginTop: '-32px',
                                marginLeft: '-32px',
                                position: 'absolute',
                            }}
                        />
                    )}
                    <Button
                        fullWidth
                        variant="text"
                        disabled={isDisabled}
                        sx={{ mt: 1, mb: 2, color: 'white' }}
                        onClick={toggleForm}
                    >
                        {isRegistering ? t('loginPage_toggleToSignIn') : t('loginPage_toggleToRegister')}
                    </Button>
                    {!isRegistering && (
                        <Button
                            fullWidth
                            variant="text"
                            disabled={isDisabled}
                            sx={{ mt: 1, mb: 2, color: 'white' }}
                            onClick={() => navigate('/forgot-password')}
                        >
                            {t('loginPage_forgotPassword')}
                        </Button>
                    )}
                </Box>
            </Paper>
        </Container>
    );


};

export default LoginPage;
