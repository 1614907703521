import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Container,
  Grid,
  ButtonGroup,
  Button,
  TextField,
} from "@mui/material";
import ImagesTabComponent from "./GalleryTabs/ImagesTab";
import VideosTabComponent from "./GalleryTabs/VideosTab";
import axios from "axios";
import { getToken } from "../../services/authentication";
import {
  getActiveCompany,
  updateCachedSplitScreenOption,
} from "../../services/localStorage";
import { MediaItem } from "../../models/media";
import { useTranslation } from "react-i18next";
import { getTvLimit } from "../../helpers/common";
import { useSnackbar } from "../layout/SnackbarContext";

const API_URL = process.env.REACT_APP_API_URL;
const tvIcon = process.env.PUBLIC_URL + "/assets/images/settings/tv.png";

const GalleryComponent: React.FC = () => {
  const [selectedTv, setSelectedTv] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [companyMedia, setCompanyMedia] = useState<MediaItem[]>([]);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [tvIds, setTvIds] = useState<number[]>([]);
  const [splitScreenOptions, setSplitScreenOptions] = useState<number[]>([]);
  const [activeButton, setActiveButton] = useState<number>(0);
  const [wifiSsid, setWifiSsid] = useState<string>("");
  const [wifiPassword, setWifiPassword] = useState<string>("");
  const [rememberPin, setRememberPin] = useState<boolean>(false);

  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();


  const handleTvChange = (event: any, newValue: number) => {
    setSelectedTv(newValue);
  }

  const handleRememberPinChange = async (newValue: boolean) => {
    const realTvId = tvIds[selectedTv];
    await axios.post(`${API_URL}/tv/updateRememberPin`, { tvId: realTvId, rememberPin: newValue }, {
      headers: {},
    });
    showSnackbar("Remember Pin changed!", "success");

    setRememberPin(newValue);
  }

  const handleTabChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setSelectedTab(newValue);
  };

  const getCompanyImages = () => {
    return companyMedia.filter(
      (media) => media.mediaType === "image" && media.tvId === tvIds[selectedTv]
    );
  };

  const getCompanyVideos = () => {
    return companyMedia.filter(
      (media) => media.mediaType === "video" && media.tvId === tvIds[selectedTv]
    );
  };

  const refreshImages = () => {
    setForceRefresh(!forceRefresh);
  };

  const getCompanyMedia = useCallback(async (): Promise<void> => {
    try {
      const token: string = getToken();
      const company = getActiveCompany();
      if (!company) return;
      const companyId = company.id;
      const tvs = company.tvs.sort((a, b) => a.id - b.id); // Sort TV objects by ID
      const tvIds = tvs.map((tv) => tv.id); // Extract sorted TV IDs
      const splitScreenOptions = tvs.map((tv) => tv.splitScreenOption); // Extract split screen options
      const rememberPinOption = tvs.map((tv) => tv.rememberPin); // Extract remember pin option
      console.log("rememberPinOption: ", rememberPinOption[selectedTv])
      const selectedTvWifiSettings = tvs[selectedTv]?.wifiSettings;

      const response = await axios.get<{ mediaItems: MediaItem[] }>(
        `${API_URL}/media/getAllMediaForCompany/${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const mediaItems = response.data.mediaItems;
      setCompanyMedia(mediaItems);

      // TODO: Tv limits need to work in order to limit
      // Right now we are showing all the tvs to the customer
      const tvLimits = getTvLimit(
        company.subscriptionPlanId,
        company.customLimits
      );
      console.log('', tvLimits);
      setTvIds(tvIds);
      setSplitScreenOptions(splitScreenOptions);
      setWifiSsid(selectedTvWifiSettings?.ssid || '');
      setWifiPassword(selectedTvWifiSettings?.password || '');
      setActiveButton(splitScreenOptions[selectedTv] || 0); // Set the active button based on the splitScreenOption of the selected TV
      setRememberPin(rememberPinOption[selectedTv] || false);
    } catch (error: any) {
      console.error("Error fetching company media:", error);
    }
  }, [selectedTv]);

  useEffect(() => {
    getCompanyMedia();
  }, [forceRefresh, getCompanyMedia]);

  useEffect(() => {
    setActiveButton(splitScreenOptions[selectedTv] || 0); // Update the active button when the selected TV changes
  }, [selectedTv, splitScreenOptions]);

  const updateSplitScreenSetting = async (option: number) => {
    try {
      const token: string = getToken();
      const company = getActiveCompany();
      if (!company) return;
      const companyId = company.id;
      const realTvId = tvIds[selectedTv];

      await axios.post(
        `${API_URL}/tv/updateSplitScreen`,
        { companyId, tvId: realTvId, splitScreenOption: option },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSplitScreenOptions((prev) => {
        const newOptions = [...prev];
        newOptions[selectedTv] = option;
        return newOptions;
      });

      setActiveButton(option);

      // Update the cached company data
      updateCachedSplitScreenOption(realTvId, option);
    } catch (error: any) {
      console.error("Error updating split screen setting:", error);
    }
  };

  const saveWifiSettings = async () => {
    try {
      const token: string = getToken();
      const company = getActiveCompany();
      if (!company) {
        showSnackbar("No active company found!", "error");
        return;
      }
      //const companyId = company.id;
      const realTvId = tvIds[selectedTv];

      await axios.post(
        `${API_URL}/tv/updateWifiSettings`,
        { tvId: realTvId, ssid: wifiSsid, password: wifiPassword },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      showSnackbar(t("galleryComponent_WifiSavedSuccess"), "success");
    } catch (error: any) {
      console.error("Error saving Wi-Fi settings:", error);
      showSnackbar(t("galleryComponent_WifiSavedError"), "error");
    }
  };


  return (
    <Container sx={{ mt: 5 }}>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: "primary.main", mb: 2 }}>
            {t("galleryComponent_title")}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Tabs
              value={selectedTv}
              onChange={handleTvChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="TV Tabs"
              sx={{
                ".MuiTabs-flexContainer": {
                  backgroundColor: "transparent",
                },
                ".MuiTab-root": {
                  color: "grey",
                  "&.Mui-selected": {
                    color: "white",
                  },
                },
              }}
            >
              {tvIds.map((tvId, index) => (
                <Tab
                  key={tvId}
                  label={
                    <Box component="span">
                      <img
                        src={tvIcon}
                        alt={`TV ${index + 1}`}
                        style={{ width: 24, height: 24 }}
                      />
                    </Box>
                  }
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                />
              ))}
            </Tabs>
          </Box>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="Gallery Tabs"
            sx={{
              ".MuiTabs-flexContainer": {
                backgroundColor: "transparent",
              },
              ".MuiTab-root": {
                color: "grey",
                "&.Mui-selected": {
                  color: "white",
                },
              },
            }}
          >
            <Tab
              label={t("galleryComponent_tabImages")}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            />
            <Tab
              label={t("galleryComponent_tabVideos")}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            />
            <Tab
              label={t("galleryComponent_tabSettings")}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            />
          </Tabs>

          <Box sx={{ p: 2 }}>
            {selectedTab === 0 && (
              <ImagesTabComponent
                refreshImages={refreshImages}
                data={getCompanyImages()}
                tvId={tvIds[selectedTv]}
              />
            )}
            {selectedTab === 1 && (
              <VideosTabComponent
                refreshVideos={refreshImages}
                data={getCompanyVideos()}
                tvId={tvIds[selectedTv]}
              />
            )}
            {selectedTab === 2 && (
              <Box>
                <Grid container spacing={2}>
                  {/* Split Screen Options */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("galleryComponent_splitScreen")}
                    </Typography>
                    <ButtonGroup
                      variant="contained"
                      aria-label="split screen button group"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        onClick={() => updateSplitScreenSetting(0)}
                        variant={activeButton === 0 ? "contained" : "outlined"}
                      >
                        None
                      </Button>
                      <Button
                        onClick={() => updateSplitScreenSetting(4)}
                        variant={activeButton === 4 ? "contained" : "outlined"}
                      >
                        2x2
                      </Button>
                      <Button
                        onClick={() => updateSplitScreenSetting(6)}
                        variant={activeButton === 6 ? "contained" : "outlined"}
                      >
                        3x3
                      </Button>
                    </ButtonGroup>
                  </Grid>

                  {/* Remember PIN Options */}
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">
                      {t("galleryComponent_rememberPin")}
                    </Typography>
                    <ButtonGroup
                      variant="contained"
                      aria-label="remember pin button group"
                      sx={{ mt: 2 }}
                    >
                      <Button
                        onClick={() => handleRememberPinChange(true)}
                        variant={rememberPin ? "contained" : "outlined"}
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => handleRememberPinChange(false)}
                        variant={!rememberPin ? "contained" : "outlined"}
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>

                {/* Wi-Fi Settings Inputs */}
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6">
                    {t("galleryComponent_wifiSettings")}
                  </Typography>

                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "text.disabled", mb: 2 }}
                    >
                      {t("galleryComponent_wifiName")}
                    </Typography>
                    <TextField
                      placeholder={t("galleryComponent_enterWifiName")}
                      fullWidth
                      variant="outlined"
                      value={wifiSsid}
                      onChange={(e) => setWifiSsid(e.target.value)}
                      sx={{
                        backgroundColor: "#3D3D3D",
                        "& .MuiOutlinedInput-input": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                          },
                        },
                      }}
                    />
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "text.disabled", mb: 2 }}
                    >
                      {t("galleryComponent_wifiPassword")}
                    </Typography>
                    <TextField
                      placeholder={t("galleryComponent_enterWifiPassword")}
                      fullWidth
                      variant="outlined"
                      value={wifiPassword}
                      onChange={(e) => setWifiPassword(e.target.value)}
                      sx={{
                        backgroundColor: "#3D3D3D",
                        "& .MuiOutlinedInput-input": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                          },
                        },
                      }}
                    />
                  </Box>

                  <Button
                    onClick={saveWifiSettings}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1 }}
                  >
                    {t("galleryComponent_saveWifiSettings")}
                  </Button>
                </Box>
              </Box>
            )}

          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GalleryComponent;
