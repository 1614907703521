import React from 'react';
import { MediaItem } from '../../../models/media';
import MediaTabComponent from './MediaTab';

export interface MediaComponentProps {
    data: MediaItem[];
    refreshVideos: () => void;
    tvId: number;
}

const VideosTabComponent: React.FC<MediaComponentProps> = ({ data, refreshVideos, tvId }) => {
    return <MediaTabComponent data={data} refreshMedia={refreshVideos} tvId={tvId} />;
};

export default VideosTabComponent;
