import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  TextField,
  IconButton,
  Snackbar,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import axios from 'axios';
import { SubscriptionPlan, SubscriptionPlanDb } from '../models/subscriptionPlans';
import { convertArrayToLimits } from '../helpers/limits';
import { getToken } from '../services/authentication';
import theme from '../helpers/theme';

const API_URL = process.env.REACT_APP_API_URL;

const AdminEditSubscriptions: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState<number | null>(null); // Use ID to track which card is being edited
  const [editedSubscriptions, setEditedSubscriptions] = useState<{
    [id: number]: SubscriptionPlan;
  }>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const token = getToken();
        const response = await axios.get<SubscriptionPlanDb[]>(
          `${API_URL}/subscription/getAllSubscriptions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const fetchedSubscriptions = response.data.map((subscription) => ({
          ...subscription,
          defaultLimits: convertArrayToLimits(subscription.defaultLimits),
        }));

        setSubscriptions(fetchedSubscriptions);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleEditToggle = (id: number) => {
    setEditMode(editMode === id ? null : id); // Toggle edit mode for specific ID
    setEditedSubscriptions({
      ...editedSubscriptions,
      [id]: subscriptions.find((sub) => sub.id === id) || ({} as SubscriptionPlan),
    });
  };

  const handleFieldChange = (
    id: number,
    field: keyof SubscriptionPlan,
    value: any
  ) => {
    setEditedSubscriptions({
      ...editedSubscriptions,
      [id]: {
        ...editedSubscriptions[id],
        [field]: value,
      },
    });
  };

  const handleLimitsChange = (
    subscriptionId: number,
    limitKey: keyof SubscriptionPlan['defaultLimits'],
    value: number
  ) => {
    setEditedSubscriptions((prevState) => ({
      ...prevState,
      [subscriptionId]: {
        ...prevState[subscriptionId],
        defaultLimits: {
          ...prevState[subscriptionId].defaultLimits,
          [limitKey]: value,
        },
      },
    }));
  };

  const handleSave = async (id: number) => {
    const updatedSubscription = editedSubscriptions[id];
    if (!updatedSubscription) {
      console.error('Missing subscription data');
      return;
    }

    try {
      const token = getToken();
      await axios.put(`${API_URL}/subscription/updateSubscription`, updatedSubscription,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSubscriptions(subscriptions.map((sub) => sub.id === id ? updatedSubscription : sub));
      setEditMode(null);

      // Show success Snackbar
      setSnackbarMessage('Subscription updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving subscription:', error);

      // Show error Snackbar
      setSnackbarMessage('Failed to update subscription.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };



  const handleCancel = (id: number) => {
    setEditMode(null);
    setEditedSubscriptions((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: 'primary.main', textAlign: 'center', mb: 4 }}
      >
        Subscription Plans
      </Typography>
      <Grid container spacing={4}>
        {subscriptions.sort((a, b) => a.id - b.id).map((subscription) => {
          const isEditing = editMode === subscription.id; // Check edit mode by ID
          return (
            <Grid item xs={12} md={6} lg={6} key={subscription.id}>
              <div className="card-with-fading-border">
                <Card
                  variant="outlined"
                  sx={{
                    position: 'relative',
                    border: '4px solid transparent',
                    borderRadius: '16px',
                    background: 'black',
                    borderColor: 'primary.main',
                    boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.5)',
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        {isEditing ? (
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Title"
                            value={editedSubscriptions[subscription.id]?.title || ''}
                            onChange={(e) =>
                              handleFieldChange(subscription.id, 'title', e.target.value)
                            }
                            sx={{ mb: 2, backgroundColor: '#333', color: 'white', '& .MuiOutlinedInput-root': { borderColor: 'primary.main' } }}
                          />
                        ) : (
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 'bold',
                              color: 'primary.main',
                              mb: 2,
                            }}
                          >
                            {subscription.title}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: 'right' }}>
                        {isEditing ? (
                          <>
                            <IconButton onClick={() => handleSave(subscription.id)}>
                              <SaveIcon sx={{ color: 'primary.main' }} />
                            </IconButton>
                            <IconButton onClick={() => handleCancel(subscription.id)}>
                              <CancelIcon sx={{ color: 'error.main' }} />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            onClick={() => handleEditToggle(subscription.id)}
                          >
                            <EditIcon sx={{ color: 'primary.main' }} />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Description"
                        value={
                          editedSubscriptions[subscription.id]?.description || ''
                        }
                        onChange={(e) =>
                          handleFieldChange(subscription.id, 'description', e.target.value)
                        }
                        sx={{ mb: 2, backgroundColor: '#333', color: 'white', '& .MuiOutlinedInput-root': { borderColor: 'primary.main' } }}
                      />
                    ) : (
                      <Typography variant="body2" sx={{ mb: 2, color: 'white' }}>
                        {subscription.description}
                      </Typography>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'bold', color: 'white', mr: 1 }}
                        component="div"
                      >
                        Price: €
                      </Typography>
                      {isEditing ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="number"
                          value={editedSubscriptions[subscription.id]?.price || 0}
                          onChange={(e) =>
                            handleFieldChange(subscription.id, 'price', parseFloat(e.target.value))
                          }
                          sx={{ mb: 2, backgroundColor: '#333', color: 'white', '& .MuiOutlinedInput-root': { borderColor: 'primary.main' } }}
                        />
                      ) : (
                        <Typography variant="body2" sx={{ color: 'white' }}>
                          {subscription.price.toFixed(2)}
                        </Typography>
                      )}
                    </Box>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Discount"
                        value={editedSubscriptions[subscription.id]?.discount || ''}
                        onChange={(e) =>
                          handleFieldChange(subscription.id, 'discount', e.target.value)
                        }
                        sx={{ mb: 2, backgroundColor: '#333', color: 'white', '& .MuiOutlinedInput-root': { borderColor: 'primary.main' } }}
                      />
                    ) : (
                      subscription.discount && (
                        <Typography
                          variant="subtitle2"
                          sx={{ color: 'secondary.main', mb: 1 }}
                        >
                          Discount: {subscription.discount}
                        </Typography>
                      )
                    )}
                    <Typography variant="subtitle2" sx={{ mb: 1, color: 'white' }}>
                      Duration:
                      {isEditing ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={
                            editedSubscriptions[subscription.id]?.duration || ''
                          }
                          onChange={(e) =>
                            handleFieldChange(
                              subscription.id,
                              'duration',
                              e.target.value
                            )
                          }
                          sx={{ mb: 2, backgroundColor: '#333', color: 'white', '& .MuiOutlinedInput-root': { borderColor: 'primary.main' } }}
                        />
                      ) : (
                        subscription.duration
                      )}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ mb: 1, color: 'white' }}>
                      Type:
                      {isEditing ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={editedSubscriptions[subscription.id]?.type || ''}
                          onChange={(e) =>
                            handleFieldChange(subscription.id, 'type', e.target.value)
                          }
                          sx={{ mb: 2, backgroundColor: '#333', color: 'white', '& .MuiOutlinedInput-root': { borderColor: 'primary.main' } }}
                        />
                      ) : (
                        subscription.type
                      )}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ mb: 2, color: 'white' }}>
                      Limits:
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      {Object.entries(subscription.defaultLimits).map(
                        ([limitKey, value]) => (
                          <Box key={`${subscription.id}-${limitKey}`} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Typography
                              variant="body2"
                              sx={{ color: 'white', mr: 1 }}
                              component="div"
                            >
                              {limitKey}:
                            </Typography>
                            {isEditing ? (
                              <TextField
                                fullWidth
                                variant="outlined"
                                type="number"
                                value={
                                  editedSubscriptions[subscription.id]?.defaultLimits[
                                  limitKey as keyof SubscriptionPlan['defaultLimits']
                                  ] || 0
                                }
                                onChange={(e) =>
                                  handleLimitsChange(
                                    subscription.id,
                                    limitKey as keyof SubscriptionPlan['defaultLimits'],
                                    parseInt(e.target.value, 10)
                                  )
                                }
                                sx={{ mb: 2, backgroundColor: '#333', color: 'white', '& .MuiOutlinedInput-root': { borderColor: 'primary.main' } }}
                              />
                            ) : (
                              <Typography variant="body2" sx={{ color: 'white' }}>
                                {value}
                              </Typography>
                            )}
                          </Box>
                        )
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{
            backgroundColor: 'black',
            color: 'primary.main',
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminEditSubscriptions;
