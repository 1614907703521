import React, { useState } from 'react';
import { Box, Paper, TextField, Button, Typography, Container, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../services/authentication';
import { useTranslation } from 'react-i18next';

const ForgotPasswordPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [loginError, setLoginError] = useState<string | null>(null);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleForgotPassword = async (event: any) => {
        event.preventDefault();
        setLoginError('');
        if (!email) {
            setLoginError(t('forgotPasswordPage_errorValidEmail'));
            return;
        }
    
        const [success, message] = await forgotPassword(email);
        if (success) {
            setIsEmailSent(true);
        } else {
            setLoginError(message);
        }
    };
    return (
        <Container component="main" maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(to bottom, #ffffff, #ff4600)',
        }}>
            <Paper
                elevation={6}
                sx={{
                    maxWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    borderRadius: 2,
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <img src="/assets/images/logo/logo_nobg.svg" alt="Logo" style={{ height: '50px', width: '250px', marginBottom: '20px' }} />
                <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                    {t('forgotPasswordPage_forgotPasswordTitle')}
                </Typography>
                {loginError && (
                    <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                        {loginError}
                    </Alert>
                )}
                <Box component="form" onSubmit={handleForgotPassword} noValidate sx={{ mt: 1, width: '100%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('forgotPasswordPage_emailLabel')}
                        name="email"
                        autoComplete="email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ input: { color: 'white' }, label: { color: 'white' } }}
                    />
                    {!isEmailSent ? <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleForgotPassword(e)}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t('forgotPasswordPage_changePassword')}
                    </Button> :
                        <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                            {t('forgotPasswordPage_emailSent')}
                        </Typography>}
                    <Button
                        fullWidth
                        variant="text"
                        sx={{ mt: 1, mb: 2, color: 'white' }}
                        onClick={() => navigate('/login')}
                    >
                        {t('forgotPasswordPage_backToSignIn')}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default ForgotPasswordPage;
