import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../services/authentication"; // Adjust the path to your auth service

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
