import { createTheme} from "@mui/material";

declare module "@mui/material/styles" {
    interface Palette {
    }
  
    interface PaletteOptions {
    }
  }

const theme = createTheme({
    palette: {
        background: {default: "#171717"}, // dark background color
        primary: {main: "#FE8639"}, // primary orange shade
        secondary: {main: `rgba(254, 134, 57, 0.5)`}, // secondary orange shade
        error: {main: '#D72A2A'}, // red
        warning: {main: '#FC7B09'}, //orange
        info: {main: '#6B7D6A'}, // grey
        success: {main: '#09FE00'}, // Green
        text: {primary: '#FE8639', // black
         secondary: 'white', //white
         disabled: 'grey' // grey
        },
    },
    components: {
        // Override styles for the Menu component used by Select dropdowns
        MuiMenu: {
          styleOverrides: {
            paper: {
              backgroundColor: '#FFF6ED', // Background color for the dropdown
            },
    
         },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              "&:-webkit-autofill": {
                transitionDelay: "9999s",
                transitionProperty: "background-color, color",
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              // Text color for the dropdown items
              color: '#3D3D3D',
              // Change text color for the selected and hovered items
              '&.Mui-selected, &:hover': {
                backgroundColor: '#FFF6ED', // or any other color for hover/selected state
              },
            },
          },
        },
      },
});
 
export default theme;