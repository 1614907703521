import React from 'react';
import { Dialog, DialogTitle, FormControl, Select, MenuItem, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CompanyBare, User } from '../models/backend';

interface SettingsDialogProps {
  open: boolean;
  user: User | undefined;
  onClose: (selectedCompany: CompanyBare | null) => void;
}

const SettingsDialog: React.FC<SettingsDialogProps> = ({ open, user, onClose }) => {
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    onClose(null);
  };

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          border: 'thick solid',
          borderColor: 'primary.main',
          borderRadius: '16px',
          backgroundColor: 'black'
        }
      }}
    >
      <DialogTitle sx={{ color: 'white' }}>
        {t('settingsDialog_yourPin')} {user?.digitPin}
      </DialogTitle>
      <DialogTitle sx={{ color: 'white' }}>
        {t('settingsDialog_yourRegion')} {user?.companies[0].regionId}
      </DialogTitle>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '16px' }}>
        <Typography variant="subtitle1" sx={{ color: 'white' }}>Language:</Typography>
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <Select
            id="language-select"
            value={i18n.language}
            onChange={(e) => handleChangeLanguage(e.target.value as string)}
            displayEmpty
            sx={{
              backgroundColor: '#3D3D3D',
              '& .MuiSelect-select': {
                color: 'white',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
            }}
          >
            <MenuItem value="en-US">English</MenuItem>

            <MenuItem value="si">Slovenian</MenuItem>
            <MenuItem value="it">Italian</MenuItem>
            <MenuItem value="de">German</MenuItem>
            <MenuItem value="hr">Croatian</MenuItem>
            <MenuItem value="mk">Macedonian</MenuItem>
            <MenuItem value="sr">Serbian</MenuItem>

          </Select>
        </FormControl>
      </Box>
    </Dialog>
  );
};

export default SettingsDialog;
