import React, { useState } from 'react';
import { Grid, TextField, IconButton, InputAdornment, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';

const InstagramComponent: React.FC = () => {
  const [copySuccess, setCopySuccess] = useState<string>('');
  const instagramLink = 'instagram.com/user33';
  const { t } = useTranslation();
    
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(instagramLink);
      setCopySuccess(t('instagramComponent_copied'));
      setTimeout(() => setCopySuccess(''), 2000); // Reset the message after 2 seconds
    } catch (err) {
      setCopySuccess(t('instagramComponent_failedToCopy'));
    }
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <TextField
          value={instagramLink}
          variant="filled"
          fullWidth
          disabled
          InputProps={{
            disableUnderline: true, // Remove the underline to hide borders
            style: {
              color: '#aaa', // Styles the input text
              backgroundColor: '#333', // Darker grey background
              padding: '0px 0px', // Reduced padding
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={copySuccess || t('instagramComponent_copyLink')}>
                  <IconButton onClick={copyToClipboard} edge="end">
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          // Override the InputLabelProps to adjust the vertical alignment
          InputLabelProps={{
            shrink: true,
            style: { transform: 'translate(14px, -6px) scale(0.75)' }, // Adjust the position of the label
          }}
          sx={{
            // Override the styles for the filled variant
            '& .MuiFilledInput-root': {
              paddingTop: 0,
              paddingBottom: 0,
              alignItems: 'center',
            },
            // Adjust the height of the input element
            '& .MuiInputBase-input': {
              height: '1.4375em', // Default height for MUI text fields
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default InstagramComponent;
