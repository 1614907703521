import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { fetchMediaLinks } from '../helpers/s3Service';
import { MediaItem } from '../models/media';
import { useTranslation } from 'react-i18next';

interface AdPlayerProps {
    companyId: number;
}

const AdPlayer: React.FC<AdPlayerProps> = ({ companyId }) => {
    const [mediaUrls, setMediaUrls] = useState<MediaItem[]>([]);
    const [loadingError, setLoadingError] = useState<string>('');
    const { t } = useTranslation();

    useEffect(() => {
        const getMediaLinks = async () => {
            try {
                const links: MediaItem[] = await fetchMediaLinks(companyId);
                if (!links.length) {
                    setLoadingError(t('adPlayer_noMedia')); // Set no media message
                    return;
                }
                setMediaUrls(links);
            } catch (error) {
                setLoadingError(t('adPlayer_loadingError')); // Set loading error message
            }
        };
        getMediaLinks();
    }, [companyId, t]);

    const isVideo = (url: string) => /\.(mp4|webm|ogg|mov|avi|mkv)$/i.test(url);

    const renderMedia = (url: string, index: number) => {
        return (
            <div key={index} className="carousel-item-container">
                <div className="static-logo">
                    <img src="/assets/images/logo/trans_logo.png" alt="Logo" />
                </div>
                {isVideo(url) ? (
                    <ReactPlayer
                        url={url}
                        playing
                        loop
                        muted={true}
                        controls={false}
                        width="100%"
                        height="100%"
                    />
                ) : (
                    <div className="carousel-image-container carousel-image-fadeout">
                        <img src={url} alt={`Slide ${index + 1}`} className="carousel-image" />
                    </div>
                )}
            </div>
        );
    };

    if (loadingError) {
        return <div>{loadingError}</div>; // Display the error or status message
    }

    return (
        <Carousel autoPlay showThumbs={false} showStatus={false} infiniteLoop showIndicators={false}>
            {mediaUrls.map((item, index) => renderMedia(item.mediaLink, index))}
        </Carousel>
    );
};

export default AdPlayer;
