import { FC } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import { Home, Business, Assignment, Menu, Subscriptions } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface AdminDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const AdminDrawer: FC<AdminDrawerProps> = ({ open, onClose, onOpen }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigation = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={onOpen}
        sx={{
          position: 'absolute',
          top: 16,
          left: 16,
          color: 'primary.contrastText',
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }}
      >
        <Menu />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={onClose}>
        <Box
          sx={{
            width: 250,
            backgroundColor: theme.palette.background.default,
            height: '100%',
            color: theme.palette.text.secondary,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              p: 2,
              textAlign: 'center',
              color: theme.palette.primary.main,
              fontWeight: 'bold',
            }}
          >
            Admin Panel
          </Typography>
          <Divider />
          <List>
            <ListItem button onClick={() => handleNavigation('/')}>
              <ListItemIcon>
                <Home color="primary" />
              </ListItemIcon>
              <ListItemText primary="Main Page" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/ad-requests')}>
              <ListItemIcon>
                <Assignment color="primary" />
              </ListItemIcon>
              <ListItemText primary="Ad Requests" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/all-companies')}>
              <ListItemIcon>
                <Business color="primary" />
              </ListItemIcon>
              <ListItemText primary="All Companies" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/edit-subscriptions')}>
              <ListItemIcon>
                <Subscriptions color="primary" />
              </ListItemIcon>
              <ListItemText primary="Edit Subscriptions" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default AdminDrawer;
