import React, { useState } from 'react';
import { Typography, Paper, Grid, Container, Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StatisticsComponent = () => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);

    // Mock data
    const data = {
        clicksCTR: '33%',
        views: '1500',
        relativeViews: '3000', // Placeholder for relative views
        qrCodeScans: '100', // Placeholder for QR Code scans
        totalAdDuration: '1h 1min 40sec', // Placeholder for total ad duration
        ageGroups: '22-28',
        genderDistribution: 'Male 51%',
        locations: 'Koper, Izola, Piran', // Renamed from location to locations
        performanceTime: '05:00-16:00'
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Container sx={{ mt: 5 }}>
            <Typography variant="h6" color="primary" gutterBottom>
                {t('statisticsComponent_title')}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'grey', mb: 2 }}>
                {t('statisticsComponent_description')}
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, display: 'flex', justifyContent: 'center' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="statistics tabs" centered>
                    <Tab label="Daily" />
                    <Tab label="Monthly" />
                    <Tab label="Yearly" />
                </Tabs>
            </Box>

            <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default' }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
                    {t('statisticsComponent_impressionsHeading')}
                </Typography>
                <Grid container justifyContent="space-between">
                    <Typography sx={{color: 'grey'}}>{t('statisticsComponent_clicksCTR')}</Typography>
                    <Typography>{data.clicksCTR}</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography sx={{color: 'grey'}}>{t('statisticsComponent_views')}</Typography>
                    <Typography>{data.views}</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography sx={{color: 'grey'}}>{t('statisticsComponent_relativeViews')}</Typography>
                    <Typography>{data.relativeViews}</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography sx={{color: 'grey'}}>{t('statisticsComponent_qrCodeScans')}</Typography>
                    <Typography>{data.qrCodeScans}</Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Typography sx={{color: 'grey'}}>{t('statisticsComponent_totalAdDuration')}</Typography>
                    <Typography>{data.totalAdDuration}</Typography>
                </Grid>
            </Paper>

            <Paper sx={{ p: 2, mb: 2, backgroundColor: 'background.default' }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
                    {t('statisticsComponent_audienceDemographics')}
                </Typography>
                <Grid container justifyContent="space-between">
                    <Typography sx={{color: 'grey'}}>{t('statisticsComponent_locations')}</Typography>
                    <Typography>{data.locations}</Typography>
                </Grid>
            </Paper>

            <Paper sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
                    {t('statisticsComponent_performanceHeading')}
                </Typography>
                <Grid container justifyContent="space-between">
                    <Typography sx={{color: 'grey'}}>{t('statisticsComponent_timeOfDay')}</Typography>
                    <Typography>{data.performanceTime}</Typography>
                </Grid>
            </Paper>
        </Container>
    );
};

export default StatisticsComponent;
