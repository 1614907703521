import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Typography, Paper, Snackbar, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { getToken } from '../../../services/authentication';
import { getActiveCompany } from '../../../services/localStorage';
import { MediaItem } from '../../../models/media';
import { useTranslation } from 'react-i18next';
import ScheduleDialog from './ScheduleDialog';

const API_URL = process.env.REACT_APP_API_URL;

export interface MediaComponentProps {
    data: MediaItem[];
    refreshMedia: () => void;
    tvId: number;
}

const MediaTabComponent: React.FC<MediaComponentProps> = ({ data, refreshMedia, tvId }) => {
    const [open, setOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState<MediaItem | undefined>();
    const [isLocal, setIsLocal] = useState<boolean>(false);
    const [isGlobal, setIsGlobal] = useState<boolean>(false);
    const [deleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

    const { t } = useTranslation();

    const handleOpen = (media: MediaItem) => {
        setSelectedMedia(media);
        setOpen(true);
        if (media.ad) {
            setIsLocal(media.ad.usedAs.includes('local'));
            setIsGlobal(media.ad.usedAs.includes('global'));
        } else {
            setIsLocal(false);
            setIsGlobal(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (selectedMedia?.ad) {
            setIsLocal(selectedMedia.ad?.usedAs.includes('local')!);
            setIsGlobal(selectedMedia.ad?.usedAs.includes('global')!);
        }
    }, [selectedMedia]);

    const deleteMedia = async () => {
        try {
            const token: string = getToken();
            const mediaId = selectedMedia?.id;

            await axios.post(
                `${API_URL}/media/deleteMedia`,
                { mediaId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            setSnackbarMessage("Media deleted successfully!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setIsDeleteDialogOpen(false);
            setOpen(false);
            refreshMedia();
        } catch (error: any) {
            console.error('Error deleting media:', error);
            setSnackbarMessage("Error deleting media: " + error.response.data.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const toggleMarkMediaAsAd = async (local: boolean, global: boolean, schedulingInfo?: any) => {
        try {
            const token: string = getToken();
            const company = getActiveCompany();
            if (!company) {
                setSnackbarMessage("No active company found!");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }
            const companyId = company.id;
            const mediaId = selectedMedia?.id;
            const usedAs: string[] = [];
            if (local) usedAs.push('local');
            if (global) usedAs.push('global');

            const response = await axios.post(
                `${API_URL}/ads/markMediaAsAd`,
                { companyId, usedAs, mediaId, tvId, ...schedulingInfo },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );

            if (response.status === 200) {
                setIsLocal(local);
                setIsGlobal(global);
                setSnackbarMessage("Media marked as ad successfully!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                refreshMedia();
            }
        } catch (error: any) {
            console.error('Error toggling media as ad:', error);
            setSnackbarMessage(error.response.data.message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const renderMediaItem = (media: MediaItem, index: number) => {
        if (media.mediaType === 'image') {
            return (
                <img
                    key={index}
                    src={media.mediaLink}
                    alt={`Not showing ${index}`}
                    style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                    onClick={() => handleOpen(media)}
                />
            );
        } else if (media.mediaType === 'video') {
            return (
                <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => handleOpen(media)}>
                    <video
                        key={index}
                        src={media.mediaLink}
                        style={{ width: '100%', height: 'auto' }}
                        controls
                        onClick={(e) => e.stopPropagation()} // Stop the click event on the video element itself
                    />
                    {/* Click overlay */}
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                        }}
                    />
                </div>
            );
        }
    };

    return (
        <Box>
            <Typography variant="subtitle2" sx={{ color: 'grey', mb: 2 }}>
                {t('galleryMediaTab_showingLastMedia')}
            </Typography>

            <Grid container spacing={2}>
                {data.map((media, index) => (
                    <Grid item xs={4} sm={2} md={2} key={index}>
                        <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                            {renderMediaItem(media, index)}
                        </Paper>
                        <Box sx={{ mt: 1, color: 'text.secondary' }}>
                            <Typography variant="body2">
                                {t('galleryMediaTab_local')}: {media.ad?.usedAs.includes('local') ? (
                                    <CheckIcon sx={{ color: 'success.main' }} />
                                ) : (
                                    <CheckIcon sx={{ color: 'grey' }} />
                                )}
                            </Typography>
                            <Typography variant="body2">
                                {t('galleryMediaTab_global')}: {media.ad?.usedAs.includes('global') ? (
                                    <CheckIcon sx={{ color: 'success.main' }} />
                                ) : (
                                    <CheckIcon sx={{ color: 'grey' }} />
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <ScheduleDialog
                open={open}
                onClose={handleClose}
                media={selectedMedia}
                tvId={tvId}
                onToggleMarkMediaAsAd={toggleMarkMediaAsAd}
                onDelete={deleteMedia}
                isLocal={isLocal}
                isGlobal={isGlobal}
                deleteDialogOpen={deleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
            />

            <Modal
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{
                    outline: 'none',
                    position: 'relative',
                    backgroundColor: 'background.default',
                    padding: 2,
                    width: '90%',
                    maxWidth: '400px',
                    borderRadius: '8px',
                    border: '4px solid',
                    borderColor: 'primary.main'
                }}>
                    <Typography id="delete-modal-title" variant="h6" sx={{ textAlign: 'center', color: 'text.primary' }}>
                        {t('galleryMediaTab_deleteAd')}
                    </Typography>
                    <Typography id="delete-modal-description" sx={{ mt: 2, color: 'text.primary' }}>
                        {t('galleryMediaTab_confirmDelete')}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setIsDeleteDialogOpen(false)}
                        >
                            {t('galleryMediaTab_cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={deleteMedia}
                        >
                            {t('galleryMediaTab_delete')}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MediaTabComponent;
