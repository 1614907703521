import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import { TabImageButton } from "../conponents/TabImageButton";
import DynamicExpandableSection from "../conponents/expandable/ExpandableSection";
import CustomerSupportButton from "../conponents/CustomerSupportButton";
import {
  getActiveCompany,
  getCurrentUser,
  setActiveCompany,
} from "../services/localStorage";
import { CompanyBare, User } from "../models/backend";
import SettingsDialog from "../conponents/SettingsDialog";
import UserProfileDialog from "../conponents/UserProfileDialog";
import AdPlayer from "../conponents/AdPlayer";
import { useTranslation } from "react-i18next";

export default function MainPage() {
  const [activeComponentCard1, setActiveComponentCard1] = useState<string>("");
  const [activeComponentCard2, setActiveComponentCard2] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const [isLimitedExpanded, setIsLimitedExpanded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const userData = getCurrentUser();
    if (userData) {
      setUser(userData);
      if (userData.companies && userData.companies.length > 0)
        setActiveCompany(userData.companies[0]);

      const subscriptionPaid = userData.companies[0].subscriptionPlanId !== 1;
      setHasPaid(subscriptionPaid);
    }
  }, []);

  const activeCompany = getActiveCompany();
  const handleCard1ButtonClick = (componentName: string) => {
    if (activeComponentCard2) {
      setActiveComponentCard2("");
    }

    setActiveComponentCard1((currentComponent) =>
      currentComponent === componentName ? "" : componentName
    );
  };

  const handleCard2ButtonClick = (componentName: string) => {
    if (activeComponentCard1) {
      setActiveComponentCard1("");
    }

    setActiveComponentCard2((currentComponent) =>
      currentComponent === componentName ? "" : componentName
    );
  };

  const handleSettingsClick = () => {
    setSettingsDialogOpen(true);
  };

  const handleSettingsDialogClose = (selectedCompany: CompanyBare | null) => {
    if (selectedCompany) {
      setActiveCompany(selectedCompany);
    }
    setSettingsDialogOpen(false);
  };

  return (
    <>
      {activeCompany && <AdPlayer companyId={activeCompany.id} />}

      <Container component="main" sx={{ mt: 2, mb: 2 }}>
        {!hasPaid && (
          <div
            style={{
              backgroundColor: "yellow",
              padding: "10px",
              marginBottom: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => setIsLimitedExpanded(!isLimitedExpanded)}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {t("mainPage_limitedFeatures")} {isLimitedExpanded ? "▲" : "▼"}
            </Typography>

            {isLimitedExpanded && (
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                  <ul style={{ textAlign: "left", display: "inline-block" }}>
                    <li>{t("mainPage_featuresList.photosLimit")}</li>
                    <li>{t("mainPage_featuresList.photoRequestLimit")}</li>
                    <li>{t("mainPage_featuresList.videosLimit")}</li>
                    <li>{t("mainPage_featuresList.adsDisplay")}</li>
                  </ul>
                </Typography>
              </div>
            )}
          </div>
        )}
        <div className="card-with-fading-border">
          <Card
            variant="outlined"
            sx={(theme) => ({
              position: "relative",
              border: "4px solid transparent",
              borderRadius: "16px",
              background: "black",
            })}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      mt: 0,
                      mb: 0,
                      color: "text.secondary",
                    }}
                  >
                    {activeCompany?.name + "'s"}
                  </Typography>

                  {/* MEDIA CARD */}
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      mt: 0,
                      mb: 0.1,
                      fontWeight: "bold",
                    }}
                  >
                    {t("mainPage_media")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 3, mb: 2, color: "white" }}
                  >
                    {t("mainPage_createAds")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    onClick={handleSettingsClick}
                    color="primary"
                    aria-label="settings"
                    sx={{ fontSize: "48px" }}
                  >
                    <SettingsIcon sx={{ fontSize: "48px", color: "primary" }} />
                  </IconButton>
                  <IconButton
                    onClick={() => setIsUserDialogOpen(true)}
                    color="primary"
                    aria-label="user"
                    sx={{ fontSize: "48px" }}
                  >
                    <AccountCircleIcon
                      sx={{ fontSize: "48px", color: "primary" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="space-around"
                alignItems="center"
              >
                <TabImageButton
                  title={t("mainPage_uploadPhotos")}
                  imageSource="/assets/images/card1/edit.png"
                  onButtonClick={() => handleCard1ButtonClick("UploadPhotos")}
                  isActive={activeComponentCard1 === "UploadPhotos"}
                />
                <TabImageButton
                  title={t("mainPage_uploadVideos")}
                  imageSource="/assets/images/card1/create.png"
                  onButtonClick={() => handleCard1ButtonClick("UploadVideos")}
                  isActive={activeComponentCard1 === "UploadVideos"}
                />
                <TabImageButton
                  title={t("mainPage_requestMedia")}
                  imageSource="/assets/images/card1/videos.png"
                  onButtonClick={() =>
                    handleCard1ButtonClick("RequestImageVideo")
                  }
                  isActive={activeComponentCard1 === "RequestImageVideo"}
                />
              </Grid>
            </CardContent>
            <DynamicExpandableSection
              user={user}
              activeComponent={activeComponentCard1}
            />
          </Card>
        </div>

        <div className="card-with-fading-border">
          <Card
            variant="outlined"
            sx={(theme) => ({
              borderRadius: "16px",
              position: "relative",
              background: "black",
            })}
          >
            <CardContent>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      mt: 0,
                      mb: 0,
                      color: "text.secondary",
                    }}
                  >
                    {activeCompany?.name + "'s"}
                  </Typography>

                  {/* STUDIO CARD */}
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{ textAlign: "left", mt: 0, mb: 4, fontWeight: "bold" }}
                  >
                    {t("mainPage_studio")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="space-around"
                alignItems="center"
              >
                <TabImageButton
                  title={t("mainPage_gallery")}
                  imageSource="/assets/images/card2/gallery.png"
                  onButtonClick={() => handleCard2ButtonClick("Gallery")}
                  isActive={activeComponentCard2 === "Gallery"}
                />
                <TabImageButton
                  title={t("mainPage_statistics")}
                  imageSource="/assets/images/card2/statistics.png"
                  onButtonClick={() => handleCard2ButtonClick("Statistics")}
                  isActive={activeComponentCard2 === "Statistics"}
                  disabled={true}
                />
                <TabImageButton
                  title={t("mainPage_socialMedia")}
                  imageSource="/assets/images/card2/socials.png"
                  onButtonClick={() => handleCard2ButtonClick("SocialMedia")}
                  isActive={activeComponentCard2 === "SocialMedia"}
                  disabled={true}
                />
              </Grid>
            </CardContent>
            <DynamicExpandableSection
              user={user}
              activeComponent={activeComponentCard2}
            />
          </Card>
        </div>

        {/* Customer Support Button */}
        <CustomerSupportButton />

        <Container sx={{ mt: 10 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                src="/assets/images/common/grandOpening.png"
                alt="Grand Opening"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            {/* <Grid item xs={12}>
                            <img
                                src="/assets/images/common/grandOpeningAbstract.png"
                                alt="Grand Opening Abstract"
                                style={{ width: '100%', height: '500px' }}
                            />
                        </Grid> */}
          </Grid>
        </Container>

        <SettingsDialog
          open={settingsDialogOpen}
          user={user || undefined}
          onClose={handleSettingsDialogClose}
        />
        <UserProfileDialog
          open={isUserDialogOpen}
          user={user}
          onClose={() => setIsUserDialogOpen(false)}
        />
      </Container>
    </>
  );
}
