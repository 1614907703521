import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Paper, Button, Typography } from '@mui/material';
import { logout } from '../services/authentication';

const PaymentFinished: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentSuccess = searchParams.get('paymentSuccess') === 'true';

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleBackToHome = () => {
        navigate('/');
    };

    return (
        <Container component="main" maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(to bottom, #ffffff, #ff4600)',
        }}>
            <Paper
                elevation={6}
                sx={{
                    maxWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    borderRadius: 2,
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                    {paymentSuccess ? 'Payment Successful' : 'Payment Failed'}
                </Typography>
                {paymentSuccess ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, color: 'white' }}>
                            Payment successful. Please logout and login again.
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </React.Fragment>
                ) : (
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleBackToHome}
                    >
                        Go to Home Page
                    </Button>
                )}
            </Paper>
        </Container>
    );
};

export default PaymentFinished;
