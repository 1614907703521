import React from 'react';
import { Container, Paper, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <Container component="main" maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(to bottom, #ffffff, #ff4600)',
        }}>
            <Paper
                elevation={6}
                sx={{
                    maxWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    borderRadius: 2,
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                    {t('notFoundPage_title')}
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleBackClick}
                >
                    {t('notFoundPage_backButton')}
                </Button>
            </Paper>
        </Container>
    );
};

export default NotFoundPage;
