import React, { useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { User } from "../../models/backend";
import theme from "../../helpers/theme";
import { NewAdRequest } from "../../models/adRequest";
import { useTranslation } from "react-i18next";

const API_URL = process.env.REACT_APP_API_URL;
const stylesArray = [
  "Coffee shop",
  "Maintenance",
  "Online business",
  "Restaurant",
  "Corporate",
]; // Example styles

interface RequestAdProps {
  user: User | null;
}

const RequestAd: React.FC<RequestAdProps> = ({ user }) => {
  const [adType, setAdType] = useState("video");
  const [adStyle, setAdStyle] = useState("");
  const [adTitle, setAdTitle] = useState("");
  const [numberOfOutputs, setNumberOfOutputs] = useState<number>(0);
  const [description, setDescription] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const formClear = () => {
    setAdType("video");
    setAdTitle("");
    setAdStyle("");
    setDescription("");
    setNumberOfOutputs(0);
  };

  const handleSubmitRequest = async () => {
    if (!adStyle || !description || !adTitle || !numberOfOutputs) {
      alert("Please fill in all fields.");
      return;
    }
    if (!user) return;
    const companyId = user.companies[0].id;
    setAdType("image");
    // Construct the ad request data object
    const adRequestData: NewAdRequest = {
      title: adTitle,
      companyId: companyId,
      outputs: numberOfOutputs,
      details: description,
      style: adStyle,
      adType: adType,
    };

    try {
      setLoading(true);
      await axios.post(
        API_URL + "/ad/createAdRequest",
        adRequestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSnackbarMessage(t("requestAd_adRequestSuccess"));
      setRequestSuccess(true);
      setLoading(false);
      formClear();
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error submitting ad request", error);
      setSnackbarMessage(t("requestAd_adRequestError"));
      setRequestSuccess(false);
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Container sx={{ mt: 5 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => { }}
      >
        <CircularProgress size={64} color="inherit" />
      </Backdrop>
      <Grid container spacing={2} direction="column">
        {/* Settings Section */}
        <Grid item xs={12}>
          <Paper
            sx={(theme) => ({
              p: 2,
              backgroundColor: theme.palette.background.default,
            })}
          >
            <Typography variant="h6" sx={{ color: "white" }}>
              {t("requestAd_settings")}
            </Typography>

            <Box sx={{ mt: 3 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "text.disabled", mb: 2 }}
              >
                {t("requestAd_adTitle")}
              </Typography>
              <TextField
                placeholder={t("requestAd_enterTitle")}
                fullWidth
                variant="outlined"
                value={adTitle}
                onChange={(e) => setAdTitle(e.target.value)}
                sx={{
                  backgroundColor: "#3D3D3D",
                  "& .MuiOutlinedInput-input": {
                    color: "white", // Text color for the input
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Border color
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main", // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main", // Border color when focused
                    },
                  },
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "error.main", // Border color when error
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.disabled", // Label color
                  },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "primary.main", // Label color when focused
                  },
                }}
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" sx={{ color: "grey", mb: 1 }}>
                {t("requestAd_style")}
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Select
                  id="style-select"
                  value={adStyle}
                  onChange={(e) => setAdStyle(e.target.value as string)}
                  displayEmpty
                  sx={{
                    mt: 1,
                    backgroundColor: "#3D3D3D",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "& .MuiSelect-select": {
                      paddingLeft: 2,
                      color: "white", // Text color for the selected item
                    },
                    "& .MuiSvgIcon-root": {
                      // This styles the dropdown icon
                      color: "white",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>{t("requestAd_none")}</em>
                  </MenuItem>
                  {stylesArray.map((styleName, index) => (
                    <MenuItem key={index} value={styleName}>
                      {styleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "grey", mb: 1 }}>
                {t("requestAd_numberOfOutputs")}
              </Typography>
              <Grid container spacing={1}>
                {[1, 2, 3].map((number) => (
                  <Grid item key={number}>
                    <Button
                      variant={
                        numberOfOutputs === number ? "contained" : "outlined"
                      }
                      onClick={() => setNumberOfOutputs(number)}
                      sx={{
                        color: "white", // Text color is white for all buttons
                        borderColor: "white",
                        backgroundColor:
                          numberOfOutputs === number
                            ? "primary.main"
                            : "#3D3D3D",
                        "&:hover": {
                          backgroundColor:
                            numberOfOutputs === number
                              ? "primary.dark"
                              : "#3D3D3D",
                        },
                      }}
                    >
                      {number}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        {/* Detailed Description */}
        <Grid item xs={12}>
          <Paper
            sx={(theme) => ({
              p: 2,
              backgroundColor: theme.palette.background.default,
              mt: 1,
            })}
          >
            <Typography variant="h6" sx={{ color: "white" }}>
              {t("requestAd_detailedDescription")}
            </Typography>
            <TextField
              id="detailed-description"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t("requestAd_describeImage")}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 150 }}
              sx={{
                mt: 1,
                backgroundColor: "#3D3D3D",
                "& .MuiInputBase-input::placeholder": {
                  // Style for the placeholder text
                  color: "#B0B0B0",
                },
                "& .MuiInputBase-input": {
                  // Style for the input text
                  color: "white",
                },
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 1,
                color: "white",
              }}
            >
              {`${description.length}/150`}
            </Box>
          </Paper>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            onClick={handleSubmitRequest}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, height: "50px", borderRadius: "8px" }}
          >
            {t("requestAd_submitRequest")}
          </Button>
        </Grid>
      </Grid>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={requestSuccess ? "success" : "error"}
          sx={{
            backgroundColor: "black",
            color: "primary.main",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RequestAd;
