import { Grid, IconButton, Typography } from "@mui/material";
import theme from "../helpers/theme";

interface TabImageButtonProps {
  title: string;
  imageSource: string;
  onButtonClick: () => void;
  isActive: boolean;
  disabled?: boolean; // Add disabled prop
}

export function TabImageButton({
  imageSource,
  title,
  onButtonClick,
  isActive,
  disabled = false,
}: TabImageButtonProps) {
  return (
    <Grid item xs={4} style={{ textAlign: "center" }}>
      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          cursor: disabled ? "not-allowed" : "pointer", // Change cursor if disabled
          opacity: disabled ? 0.5 : 1, // Reduce opacity if disabled
        }}
        onClick={!disabled ? onButtonClick : undefined} // Disable onClick if disabled
      >
        <IconButton
          color="primary"
          aria-label={title.toLowerCase().split(" ")[0]}
          sx={{
            p: 0,
            borderRadius: "10px",
            width: theme.spacing(9),
            height: theme.spacing(9),
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isActive
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
            marginBottom: "8px",
            "&:hover": {
              backgroundColor: isActive
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
            },
          }}
        >
          <img
            src={imageSource}
            alt={title}
            style={{ width: "60%", height: "60%" }}
          />
        </IconButton>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
          <span
            style={{
              color: isActive
                ? theme.palette.text.primary
                : theme.palette.text.secondary,
            }}
          >
            {title.split(" ")[0]}
          </span>{" "}
          {title.split(" ")[1]}
        </Typography>
      </div>
    </Grid>
  );
}
