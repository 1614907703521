import React from 'react';
import { MediaItem } from '../../../models/media';
import MediaTabComponent from './MediaTab';

export interface MediaComponentProps {
    data: MediaItem[];
    refreshImages: () => void;
    tvId: number;
}

const ImagesTabComponent: React.FC<MediaComponentProps> = ({ data, refreshImages, tvId }) => {
    return <MediaTabComponent data={data} refreshMedia={refreshImages} tvId={tvId} />;
};

export default ImagesTabComponent;
