import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { isAdmin } from '../services/authentication';
import { Box } from '@mui/material';
import AdminDrawer from '../conponents/AdminDrawer';

const Layout: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={{ minHeight: '100vh', position: 'relative' }}>
      {isAdmin() && (
        <AdminDrawer open={drawerOpen} onOpen={handleDrawerOpen} onClose={handleDrawerClose} />
      )}
      <Outlet />
    </Box>
  );
};

export default Layout;
