import React, { useEffect, useState } from 'react';
import { Box, Paper, Button, Typography, Container, Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyUser } from '../services/authentication';
import { useTranslation } from 'react-i18next';

const VerifyMailPage: React.FC = () => {
    const [loginError, setLoginError] = useState<string | null>(null);
    const [isVerifySuccess, setisVerifySuccess] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { token } = useParams();

    const handleVerifyUser = async (token: string) => {
        setLoginError('');
        const result = await verifyUser(token);
        setisVerifySuccess(result[0])
    };

    useEffect(() => {
        if (token)
            handleVerifyUser(token);
    }, [token])

    return (
        <Container component="main" maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(to bottom, #ffffff, #ff4600)',
        }}>
            <Paper
                elevation={6}
                sx={{
                    maxWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    borderRadius: 2,
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <img src="/assets/images/logo/logo_nobg.svg" alt="Logo" style={{ height: '50px', width: '250px', marginBottom: '20px' }} />
                <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                    {t('Email Verification')}
                </Typography>
                {loginError && (
                    <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                        {loginError}
                    </Alert>
                )}
                {isVerifySuccess && <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>

                    <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                        {t('Your account has been verified')}
                    </Typography>
                    <Button
                        fullWidth
                        variant="text"
                        sx={{ mt: 1, mb: 2, color: 'white' }}
                        onClick={() => navigate('/login')}
                    >
                        {t('forgotPasswordPage_backToSignIn')}
                    </Button>
                </Box>}
            </Paper>
        </Container>
    );
};

export default VerifyMailPage;
