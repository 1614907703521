export const countryRegions: { [key: string]: { id: number; title: string; }[] } = {
  Slovenia: [
    { id: 1, title: 'Pomurska regija' },
    { id: 2, title: 'Podravska regija' },
    { id: 3, title: 'Koroška regija' },
    { id: 4, title: 'Savinjska regija' },
    { id: 5, title: 'Zasavska regija' },
    { id: 6, title: 'Posavska regija' },
    { id: 7, title: 'Jugovzhodna Slovenija' },
    { id: 8, title: 'Osrednjeslovenska regija' },
    { id: 9, title: 'Gorenjska regija' },
    { id: 10, title: 'Primorsko-notranjska regija' },
    { id: 11, title: 'Goriška regija' },
    { id: 12, title: 'Obalno-kraška regija' },
  ],
  Macedonia: [
    { id: 13, title: 'Skopje' },
    { id: 14, title: 'Bitola' },
    { id: 15, title: 'Kumanovo' },
    { id: 16, title: 'Prilep' },
    { id: 17, title: 'Tetovo' },
    { id: 18, title: 'Veles' },
    { id: 19, title: 'Ohrid' },
    { id: 20, title: 'Gostivar' },
    { id: 21, title: 'Štip' },
    { id: 22, title: 'Strumica' },
    { id: 23, title: 'Kavadarci' },
    { id: 24, title: 'Kočani' },
    { id: 25, title: 'Kičevo' },
    { id: 26, title: 'Struga' },
    { id: 27, title: 'Radoviš' },
    { id: 28, title: 'Gevgelija' },
    { id: 29, title: 'Debar' },
    { id: 30, title: 'Kriva Palanka' },
    { id: 31, title: 'Sveti Nikole' },
    { id: 32, title: 'Negotino' },
    { id: 33, title: 'Delčevo' },
    { id: 34, title: 'Vinica' },
    { id: 35, title: 'Resen' },
    { id: 36, title: 'Makedonski Brod' },
    { id: 37, title: 'Berovo' },
    { id: 38, title: 'Valandovo' },
    { id: 39, title: 'Kratovo' },
    { id: 40, title: 'Bogdanci' },
    { id: 41, title: 'Demir Hisar' },
    { id: 42, title: 'Demir Kapija' },
    { id: 43, title: 'Pehčevo' },
    { id: 44, title: 'Probištip' },
  ],
  Italy: [
    // Regions for Italy
  ],
  Croatia: [
    // Regions for Croatia
  ],
  Serbia: [
    // Regions for Serbia
  ],
};
