import { Limits } from "../models/subscriptionPlans";

export function convertArrayToLimits(limitsArray: number[]): Limits {
    return {
      tvLimit: limitsArray[0] || 0,
      uploadImageLimit: limitsArray[1] || 0,
      uploadVideoLimit: limitsArray[2] || 0,
      activeImageAdLimit: limitsArray[3] || 0,
      activeVideoAdLimit: limitsArray[4] || 0,
    };
  }