import { CompanyBare, LoginResponse, User } from "../models/backend";
import {
  SubscriptionPlan,
  SubscriptionPlanDb,
} from "../models/subscriptionPlans";
import i18n from "../i18n";
import { convertArrayToLimits } from "../helpers/limits";

export const getCurrentUser = (): User | null => {
  const loginDataStr = localStorage.getItem("wistaa_data");
  if (loginDataStr) {
    const loginData: LoginResponse = JSON.parse(loginDataStr);
    return loginData.user;
  }

  return null;
};

// Bare are only the shallow company info
export const getUserBareCompanies = (user?: User): CompanyBare[] | null => {
  let currentUser = user;

  if (!currentUser) {
    const loginDataStr = localStorage.getItem("wistaa_data");
    if (loginDataStr) {
      const loginData: LoginResponse = JSON.parse(loginDataStr);
      currentUser = loginData.user;
    }
  }

  return currentUser ? currentUser.companies : null;
};

export function setActiveCompany(company: CompanyBare) {
  localStorage.setItem("wistaa_activeCompany", JSON.stringify(company));
}

export function getActiveCompany() {
  const data = localStorage.getItem("wistaa_activeCompany");
  if (data) {
    const parsed: CompanyBare = JSON.parse(data);
    return parsed;
  }
}

export function getSubscriptionPlans(): SubscriptionPlan[] | null {
  const wistaaData = localStorage.getItem("wistaa_data");
  if (wistaaData) {
    const parsedData = JSON.parse(wistaaData);
    const subscriptionPlansDb: SubscriptionPlanDb[] =
      parsedData.subscriptionPlans || [];
    const subscriptionPlans: SubscriptionPlan[] = subscriptionPlansDb.map(
      (planDb) => ({
        ...planDb,
        defaultLimits: convertArrayToLimits(planDb.defaultLimits), // Use the conversion function here
      })
    );
    return subscriptionPlans;
  }
  return null;
}

export const getTranslatedSubscriptionPlans = (): SubscriptionPlan[] => {
  const plans = getSubscriptionPlans();
  if (!plans) return [];
  return plans.map((plan) => {
    // Using the `title` as the unique identifier for translations
    const titleKey = plan.title.replace(/\s+/g, "");
    return {
      ...plan,
      title: i18n.t(`subscriptionPlans.${titleKey}.title`),
      description: i18n.t(`subscriptionPlans.${titleKey}.description`),
      duration: i18n.t(`subscriptionPlans.${titleKey}.duration`),
    };
  });
};

export function updateCachedSplitScreenOption(
  tvId: number,
  splitScreenOption: number
) {
  const data = localStorage.getItem("wistaa_activeCompany");
  if (data) {
    const parsed: CompanyBare = JSON.parse(data);
    const updatedTvs = parsed.tvs.map((tv) =>
      tv.id === tvId ? { ...tv, splitScreenOption } : tv
    );
    const updatedCompany = { ...parsed, tvs: updatedTvs };
    localStorage.setItem(
      "wistaa_activeCompany",
      JSON.stringify(updatedCompany)
    );
  }
}
