import React, { useState } from 'react';
import { Box, Paper, TextField, Button, Typography, Container, Alert, InputAdornment, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../services/authentication';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const ResetPasswordPage: React.FC = () => {
    const [resetInfo, setResetInfo] = useState({ password: '', confirmPassword: '' });
    const [loginError, setLoginError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);

    const navigate = useNavigate();
    const { token } = useParams();
    const handleResetPassword = async (event: any) => {
        event.preventDefault();
        setLoginError('');
        if (resetInfo.password !== resetInfo.confirmPassword) {
            setLoginError("Password doesn't match");
            return;
        }
        if ((!resetInfo.password || !resetInfo.confirmPassword)) {
            setLoginError("Password doesn't match");
            return;
        }
        const result = await resetPassword(resetInfo.password, token!);

        if (!result[0]) {
            setLoginError(result[1]);
            return;
        }
        setIsPasswordReset(true);
    };

    return (
        <Container component="main" maxWidth="xl" sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(to bottom, #ffffff, #ff4600)',
        }}>
            <div style={{ position: 'absolute', left: 0, width: '100%', height: '100%', visibility: 'hidden', background: '#00000088', }}></div>
            <Paper
                elevation={6}
                sx={{
                    maxWidth: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 4,
                    borderRadius: 2,
                    background: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <img src="/assets/images/logo/logo_nobg.svg" alt="Logo" style={{ height: '50px', width: '250px', marginBottom: '20px' }} />
                <Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                    {'Forgot Password'}
                </Typography>
                {loginError && (
                    <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                        {loginError}
                    </Alert>
                )}
                <Box component="form" onSubmit={handleResetPassword} noValidate sx={{ mt: 1, width: '100%' }}>
                    {!isPasswordReset ? <> <TextField
                        margin="normal"
                        required
                        fullWidth
                        //disabled={isDisabled}
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={resetInfo.password}
                        onChange={(e) => setResetInfo({ ...resetInfo, password: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ input: { color: 'white' }, label: { color: 'white' } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirm-password"
                            value={resetInfo.confirmPassword}
                            onChange={(e) => setResetInfo({ ...resetInfo, confirmPassword: e.target.value })}
                            sx={{ input: { color: 'white' }, label: { color: 'white' } }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            //disabled={isDisabled}
                            variant="contained"
                            color="primary"
                            onClick={(e) => handleResetPassword(e)}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {'Reset password'}
                        </Button>
                    </> : <><Typography component="h1" variant="h5" sx={{ mt: 5, color: 'white' }}>
                        {'Success! Your password has been reset.'}
                    </Typography>
                        <Button
                            fullWidth
                            variant="text"
                            //disabled={isDisabled}
                            sx={{ mt: 1, mb: 2, color: 'white' }}
                            onClick={() => navigate('/login')}
                        >
                            {'Back to sign in'}
                        </Button></>}
                </Box>
            </Paper>
        </Container>
    );

};

export default ResetPasswordPage;
