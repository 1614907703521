import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomerSupportButton = () => {
    const { t } = useTranslation();

    const handleButtonClick = () => {
        window.location.href = 'mailto:connect@wistaa.com';
    };

    return (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
                mt: 10,
                py: 4, // Increase padding for vertical spacing
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none', // Prevent text from being all uppercase
                borderRadius: '30px', // Adjust for more roughened edges
                transform: 'scale(0.7)', // Scale down to 70%
                transformOrigin: 'center', // Ensure the button scales 
            }}
            onClick={handleButtonClick}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={10} style={{ textAlign: 'left' }}>
                    <Typography variant="h6" component="h2" sx={{ color: 'white', fontWeight: 'bold' }}>
                        {t('customerSupportButton_label')}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'white' }}>
                        {t('customerSupportButton_subLabel')}
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <img src="/assets/images/common/customerSupport.png" alt="Phone" style={{ width: 40, height: 40 }} />
                </Grid>
            </Grid>
        </Button>
    );
};

export default CustomerSupportButton;
