import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Paper,
    Snackbar,
    Alert,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import axios from 'axios';
import theme from '../../helpers/theme';
import { getToken } from '../../services/authentication';
import { useTranslation } from 'react-i18next';
import { getTvLimit } from '../../helpers/common';
import { getActiveCompany } from '../../services/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

const UploadVideosComponent: React.FC = () => {
    const [videoTitle, setVideoTitle] = useState('');
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTv, setSelectedTv] = useState<number>(1);
    const [tvCount, setTvCount] = useState<number>(0);
    const [tvIds, setTvIds] = useState<number[]>([]);

    const { t } = useTranslation();

    const formClear = () => {
        setVideoTitle('');
        setVideoFile(null);
        setVideoPreview(null);
    };

    useEffect(() => {
        const fetchTvs = async () => {
            const company = getActiveCompany();
            if (company) {
                const customLimits = company.customLimits;
                const subscriptionPlanId = company.subscriptionPlanId;
                const limit = getTvLimit(subscriptionPlanId, customLimits);
                setTvCount(limit);

                const tvs = company.tvs || [];
                // Sort TV IDs to ensure they are in correct order (same as in UploadPhotosComponent)
                const sortedTvs = tvs.sort((a, b) => a.id - b.id);
                setTvIds(sortedTvs.map((tv) => tv.id)); // Correctly map the sorted IDs
            }
        };

        fetchTvs();
    }, []);

    const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        setVideoFile(file);

        if (file) {
            const url = URL.createObjectURL(file);
            setVideoPreview(url);
        } else {
            setVideoPreview(null);
        }
    };

    const handleUpload = async () => {
        if (!videoFile) {
            alert('Please fill in all fields and select a video.');
            return;
        }
        const company = getActiveCompany();
        if (!company) return;
        setLoading(true);
        const companyId = company.id;
        const formData = new FormData();
        formData.append('companyId', companyId.toString());
        formData.append('title', videoTitle);
        formData.append('tvId', tvIds[selectedTv - 1].toString()); // Use the correctly mapped TV ID
        formData.append('media', videoFile);

        try {
            const token = getToken();
            const response = await axios.post(API_URL + '/company/uploadMedia', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });

            const firstUploadResult = response.data.data[0]; // Get the first upload result

            if (firstUploadResult && firstUploadResult.Location) {
                setSnackbarMessage(t('uploadVideosComponent_uploadSuccess'));
                setUploadSuccess(true);
            } else {
                setSnackbarMessage(t('uploadVideosComponent_uploadError'));
                setUploadSuccess(false);
            }

            setLoading(false);
            formClear();
            setSnackbarOpen(true);
        } catch (error: any) {
            let errorMessage = 'Error uploading video';
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }

            setSnackbarMessage(errorMessage);
            setUploadSuccess(false);
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <Container sx={{ mt: 5 }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => { }}
            >
                <CircularProgress size={64} color="inherit" />
            </Backdrop>
            <Grid container spacing={2} direction="column">
                {/* Upload Video Section */}
                <Grid item xs={12}>
                    <Paper sx={(theme) => ({ p: 2, backgroundColor: theme.palette.background.default })}>
                        <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>
                            {t('uploadVideosComponent_uploadVideo')}
                        </Typography>
                        <label htmlFor="upload-video-input">
                            <input
                                accept="video/*"
                                id="upload-video-input"
                                type="file"
                                hidden
                                onChange={handleVideoChange}
                            />
                            <Box
                                sx={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '300px',
                                    width: '100%',
                                    overflow: 'hidden',
                                    paddingBottom: '20px',
                                }}
                            >
                                {videoPreview ? (
                                    <video width="100%" height="auto" controls style={{ maxHeight: '250px' }}>
                                        <source src={videoPreview} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img
                                        src="/assets/images/card1/uploadVideo.png"
                                        alt="Upload"
                                        style={{
                                            width: '100%',
                                            maxWidth: '500px',
                                            height: 'auto',
                                            objectFit: 'cover',
                                            filter: `invert(56%) sepia(94%) saturate(2400%) hue-rotate(-10deg) brightness(102%) contrast(102%)`,
                                        }}
                                    />
                                )}
                            </Box>
                        </label>
                    </Paper>
                </Grid>

                {/* Settings Section */}
                <Grid item xs={12}>
                    <Paper sx={(theme) => ({ p: 2, backgroundColor: theme.palette.background.default })}>
                        <Typography variant="h6" sx={{ color: 'white' }}>
                            {t('uploadVideosComponent_settings')}
                        </Typography>

                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.disabled', mb: 2 }}>
                                {t('uploadVideosComponent_videoTitle')}
                            </Typography>
                            <TextField
                                placeholder={t('uploadVideosComponent_enterTitle')}
                                fullWidth
                                variant="outlined"
                                value={videoTitle}
                                onChange={(e) => setVideoTitle(e.target.value)}
                                sx={{
                                    backgroundColor: '#3D3D3D',
                                    '& .MuiOutlinedInput-input': {
                                        color: 'white',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'primary.main',
                                        },
                                    },
                                }}
                            />
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle2" sx={{ color: 'text.disabled', mb: 2 }}>
                                {t('uploadVideosComponent_selectTv')}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                {Array.from({ length: tvCount }, (_, index) => (
                                    <Button
                                        key={index}
                                        variant={selectedTv === index + 1 ? 'contained' : 'outlined'}
                                        color="primary"
                                        onClick={() => setSelectedTv(index + 1)}
                                        sx={{
                                            backgroundColor:
                                                selectedTv === index + 1 ? theme.palette.primary.main : '#3D3D3D',
                                            borderColor: 'white',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: selectedTv === index + 1 ? theme.palette.primary.dark : '#5a5a5a',
                                            },
                                        }}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>

                {/* Upload Button */}
                <Grid item xs={12}>
                    <Button
                        onClick={handleUpload}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, height: '50px', borderRadius: '8px' }}
                    >
                        {t('uploadVideosComponent_upload')}
                    </Button>
                </Grid>
            </Grid>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={uploadSuccess ? 'success' : 'error'}
                    sx={{ backgroundColor: 'black', color: 'primary.main', border: `1px solid ${theme.palette.primary.main}` }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default UploadVideosComponent;
