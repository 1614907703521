import { Navigate } from "react-router-dom";
import { isAdmin } from "../services/authentication";

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  if (!isAdmin()) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AdminRoute;
