import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import { User } from '../models/backend';
import { SubscriptionPlan } from '../models/subscriptionPlans';
import { convertSubscriptionPriceToCents } from '../helpers/common';
import { useTranslation } from 'react-i18next';
import { getTranslatedSubscriptionPlans } from '../services/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

interface SubscriptionCarouselProps {
  user: User;
}

interface SubscriptionCardProps {
  plan: SubscriptionPlan;
  handlePayment: () => Promise<void>;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ plan, handlePayment }) => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      sx={{
        border: '4px solid transparent',
        borderRadius: '16px',
        background: "black",
        width: { xs: '90%', sm: '70%', md: '50%' },
        mx: 'auto',
        my: 2,
        p: { xs: 1, sm: 2 },
      }}
    >
      <CardContent>
        <Typography variant="h6" component="h2" gutterBottom sx={{ textAlign: 'left', color: 'text.secondary' }}>
          {plan.title}
        </Typography>
        <Typography variant="subtitle2" gutterBottom sx={{ textAlign: 'left', color: 'white' }}>
          {plan.description}
        </Typography>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Typography variant="subtitle1" sx={{ color: 'grey' }}>
              {plan.discount}
            </Typography>
            <Typography variant="h4" component="h2">
              {plan.price} €
            </Typography>
            <Typography variant="subtitle2">
              {plan.duration}
            </Typography>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handlePayment}
          sx={{ mt: 2, height: { xs: '40px', sm: '50px', md: '60px' }, borderRadius: '8px' }}
        >
          {t('subscriptionCard_getStarted')}
        </Button>
      </CardContent>
    </Card>
  );
};

const SubscriptionCarousel: React.FC<SubscriptionCarouselProps> = ({ user }) => {
  const { t } = useTranslation();
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);

  useEffect(() => {
    const translatedPlans = getTranslatedSubscriptionPlans();
    setPlans(translatedPlans);
  }, []);

  const handlePayment = async (plan: SubscriptionPlan) => {
    const endpoint = plan.type === 'subscription' ? '/payment/subscription' : '/payment/onetime';
    try {
      const response = await axios.post(`${API_URL}${endpoint}`, {
        email: user.email,
        companyId: user.companies[0].id,
        lineItems: [{
          price_data: {
            product: plan.productId,
            currency: 'EUR',
            unit_amount: convertSubscriptionPriceToCents(plan.price),
          },
          quantity: 1
        }]
      });
      window.location.href = response.data.url;
    } catch (error: any) {
      console.error(`${t('subscriptionCard_errorProcessingPayment')}: ${error.message}`);
    }
  };

  // Filter out free plan
  const filteredPlans = plans.filter(plan => plan.id !== 1);

  return (
    <Carousel
      showArrows={true}
      emulateTouch={true}
      showStatus={false}  // Remove the status indicator
      showThumbs={false}  // Remove the thumbnail indicators
      showIndicators={false}  // Disable the dots
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={100}
    >
      {filteredPlans.map((plan, index) => (
        <SubscriptionCard key={index} plan={plan} handlePayment={() => handlePayment(plan)} />
      ))}
    </Carousel>
  );
};

export default SubscriptionCarousel;
