import React, { useState, useEffect } from "react";
import { Container, Typography, TextField, Box, Button, Select, MenuItem, Chip, Snackbar, Alert } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { getToken, saveAdminData } from "../services/authentication";
import { useTranslation } from "react-i18next";
import { countryRegions } from "../constants/regions";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../models/backend";
import theme from "../helpers/theme";

const API_URL = process.env.REACT_APP_API_URL;

interface Company {
  id: number;
  name: string;
  active: boolean;
  approved: boolean;
  subscriptionPlanId: number | null;
  regionId: number;
  customLimits: number[];
  ownerId: number;
}

const AdminAllCompanies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [onlineTVs, setOnlineTVs] = useState<{ companyId: string, tvIds: number[] }[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = getToken();
        const response = await axios.get<Company[]>(`${API_URL}/company/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCompanies(response.data);
        setFilteredCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    const fetchOnlineTVs = async () => {
      try {
        const response = await axios.get<{ companyId: string, tvIds: number[] }[]>(`${API_URL}/tv/getAllOnlineTvs`);
        setOnlineTVs(response.data);
      } catch (error) {
        console.error("Error fetching online TVs:", error);
      }
    };

    fetchCompanies();
    fetchOnlineTVs();
  }, []);

  const handleImpersonate = async (userId: number) => {
    try {
      const token = getToken();
      saveAdminData();

      const responseRaw = await axios.post(`${API_URL}/users/impersonate`, { targetUserId: userId }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const response: LoginResponse = responseRaw.data;
      localStorage.setItem("wistaa_data", JSON.stringify(response));

      navigate('/');
    } catch (error) {
      console.error("Impersonation failed:", error);
    }
  };

  // Handle search
  useEffect(() => {
    const query = searchQuery.toLowerCase();
    const filtered = companies.filter((company) =>
      company.name.toLowerCase().includes(query)
    );
    setFilteredCompanies(filtered);
  }, [searchQuery, companies]);

  // Map regionId to region name based on company.regionId and country
  const getRegionTitle = (regionId: number, country: string) => {
    const regions = countryRegions[country] || [];
    const region = regions.find((region) => region.id === regionId);
    return region ? region.title : "Unknown Region";
  };

  // Function to handle subscription plan change
  const changeCompanySubscriptionModel = async (companyId: number, subscriptionPlanId: number) => {
    try {
      const token = getToken();
      await axios.put(
        `${API_URL}/subscription/updateCompanySubscription`,
        { companyId, subscriptionPlanId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the state with the new subscription plan
      setCompanies((prevCompanies) =>
        prevCompanies.map((company) =>
          company.id === companyId ? { ...company, subscriptionPlanId } : company
        )
      );

      // Show success toast
      setSnackbarMessage("Subscription plan updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating subscription plan:", error);

      // Show error toast
      setSnackbarMessage("Failed to update subscription plan.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // Function to check if a TV is online for a company
  const isTVOnline = (companyId: number) => {
    const companyOnlineInfo = onlineTVs.find(tv => tv.companyId === companyId.toString());
    return companyOnlineInfo && companyOnlineInfo.tvIds.length > 0;
  };

  // Define columns for DataGrid with original names
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    // {
    //   field: "active",
    //   headerName: "Active",
    //   flex: 1,
    //   renderCell: (params) => (params.value ? "Yes" : "No"),
    // },
    // {
    //   field: "approved",
    //   headerName: "Approved",
    //   flex: 1,
    //   renderCell: (params) => (params.value ? "Yes" : "No"),
    // },
    {
      field: "subscriptionPlanId",
      headerName: "Subscription Plan",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.value || ""}
          onChange={(e) =>
            changeCompanySubscriptionModel(params.row.id, e.target.value as number)
          }
          fullWidth
          sx={{ color: "white" }}
        >
          <MenuItem value={1}>Plan 1</MenuItem>
          <MenuItem value={2}>Plan 2</MenuItem>
          <MenuItem value={3}>Plan 3</MenuItem>
          <MenuItem value={4}>Plan 4</MenuItem>
        </Select>
      ),
    },
    {
      field: "regionId",
      headerName: "Region Id",
      flex: 1,
      renderCell: (params) => getRegionTitle(params.value, 'Slovenia'),
    },
    // {
    //   field: "customLimits",
    //   headerName: "Custom Limits",
    //   flex: 1,
    //   renderCell: (params) => params.value.join(", "),
    // },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={isTVOnline(params.row.id) ? "Active" : "Inactive"}
          color={isTVOnline(params.row.id) ? "success" : "error"}
        />
      ),
    },
    {
      field: "impersonate",
      headerName: "Impersonate",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleImpersonate(params.row.ownerId)}
        >
          Impersonate
        </Button>
      ),
      flex: 1,
    },
  ];

  return (
    <Container component="main" sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h4" sx={{ color: "white", mb: 3 }}>
        {t("adminAllCompanies_title")}
      </Typography>

      {/* Search Field */}
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label={t("adminAllCompanies_search")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            input: { color: "white" },
            label: { color: "white" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
          }}
        />
      </Box>

      {/* Data Grid */}
      <Box sx={{ height: 600, width: "100%", background: "black" }}>
        <DataGrid
          rows={filteredCompanies}
          columns={columns}
          getRowId={(row) => row.id}
          sx={{
            "& .MuiDataGrid-root": {
              color: "white",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#333",
            },
            "& .MuiDataGrid-row": {
              backgroundColor: "#444",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid #555",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "white",
            },
          }}
        />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{
            backgroundColor: "black",
            color: "primary.main",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminAllCompanies;