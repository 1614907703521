import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Modal, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MediaItem } from '../../../models/media';

interface ScheduleDialogProps {
    open: boolean;
    onClose: () => void;
    media: MediaItem | undefined;
    tvId: number;
    onToggleMarkMediaAsAd: (isLocal: boolean, isGlobal: boolean, schedulingInfo?: any) => Promise<void> | void;
    onDelete: () => void;
    isLocal: boolean;
    isGlobal: boolean;
    deleteDialogOpen: boolean;
    setIsDeleteDialogOpen: (open: boolean) => void;
}

const ScheduleDialog: React.FC<ScheduleDialogProps> = ({
    open,
    onClose,
    media,
    tvId,
    onToggleMarkMediaAsAd,
    onDelete,
    isLocal,
    isGlobal,
    deleteDialogOpen,
    setIsDeleteDialogOpen }) => {
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [recurrence, setRecurrence] = useState<string>('once');
    const [isScheduling, setIsScheduling] = useState<boolean>(false);
    const { t } = useTranslation();

    const recurrenceOptions = [
        { value: 'once', label: t('recurrenceOnce') },
        { value: 'daily', label: t('recurrenceDaily') },
        { value: 'weekly', label: t('recurrenceWeekly') },
        { value: 'monthly', label: t('recurrenceMonthly') },
    ];

    useEffect(() => {
        if (media?.ad) {
            if (media.ad.startDate || media.ad.endDate || media.ad.recurrenceType) {
                setIsScheduling(true);
                setStartDate(media.ad.startDate ? dayjs(media.ad.startDate) : null);
                setEndDate(media.ad.endDate ? dayjs(media.ad.endDate) : null);
                setRecurrence(media.ad.recurrenceType || 'once');
            }
        }
    }, [media]);

    const handleButtonClick = (local: boolean, global: boolean) => {
        const info = isScheduling
            ? {
                startDate: startDate ? startDate.toISOString() : null,
                endDate: endDate ? endDate.toISOString() : null,
                recurrenceType: recurrence,
                recurrenceEndDate: endDate ? endDate.toISOString() : null,
            }
            : {};
        onToggleMarkMediaAsAd(local, global, info);
    };

    const handleToggleScheduling = () => {
        setIsScheduling((prev) => !prev);
        if (!isScheduling) {
            setStartDate(null);
            setEndDate(null);
            setRecurrence('once');
        }
    };

    function handleGlobalButtonColor() {
        if (isGlobal && media?.ad?.isActive) return "success";
        if (isGlobal && !media?.ad?.isActive) return "primary";
        return "info";
    }

    const handleDeleteClick = async () => {
        if (isLocal || isGlobal) await onToggleMarkMediaAsAd(false, false);
        setIsDeleteDialogOpen(true);
    };

    const renderMediaPreview = () => {
        if (!media) return null;
        if (media.mediaType === 'image') {
            return <img src={media.mediaLink} alt="Enlarged" style={{ maxWidth: '100%', maxHeight: '60vh', marginBottom: '20px' }} />;
        }
        if (media.mediaType === 'video') {
            return <video src={media.mediaLink} controls style={{ maxWidth: '100%', maxHeight: '60vh', marginBottom: '20px' }} />;
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="media-modal-title"
                aria-describedby="media-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{
                    outline: 'none',
                    position: 'relative',
                    backgroundColor: 'background.default',
                    padding: 2,
                    width: '90%',
                    maxWidth: '600px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    borderRadius: '8px',
                    border: '4px solid',
                    borderColor: 'primary.main'
                }}>
                    {renderMediaPreview()}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
                        <Button
                            variant="contained"
                            color={isScheduling ? 'success' : 'info'}
                            onClick={handleToggleScheduling}
                        >
                            {t('scheduleAd')}
                        </Button>
                        {isScheduling && (
                            <>
                                <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.primary' }}>
                                    {t('scheduleAd')}
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box onClick={() => document.getElementById('startDatePicker')?.focus()} sx={{ width: '100%' }}>
                                        <DateTimePicker
                                            label={t('startDate')}
                                            value={startDate}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    id: 'startDatePicker'
                                                }
                                            }}
                                            sx={{
                                                width: '100%',
                                                backgroundColor: '#3D3D3D',
                                                '& .MuiOutlinedInput-input': {
                                                    color: 'white',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.main',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.main',
                                                    },
                                                },
                                                mt: 2,
                                                mb: 2,
                                            }}
                                        />
                                    </Box>
                                    <Box onClick={() => document.getElementById('endDatePicker')?.focus()} sx={{ width: '100%' }}>
                                        <DateTimePicker
                                            label={t('endDate')}
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    id: 'endDatePicker'
                                                }
                                            }}
                                            sx={{
                                                width: '100%',
                                                backgroundColor: '#3D3D3D',
                                                '& .MuiOutlinedInput-input': {
                                                    color: 'white',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.main',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.main',
                                                    },
                                                },
                                                mt: 2,
                                                mb: 2,
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                                <FormControl sx={{
                                    width: '100%',
                                    backgroundColor: '#3D3D3D',
                                    '& .MuiSelect-select': {
                                        color: 'white',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'white',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'primary.main',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'primary.main',
                                    },
                                }}>
                                    <InputLabel>{t('recurrence')}</InputLabel>
                                    <Select
                                        value={recurrence}
                                        onChange={(e) => setRecurrence(e.target.value)}
                                        label={t('recurrence')}
                                    >
                                        {recurrenceOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                color={isLocal ? 'success' : 'info'}
                                onClick={() => handleButtonClick(!isLocal, isGlobal)}
                            >
                                {t('galleryMediaTab_local')}
                            </Button>
                            <Button
                                variant="contained"
                                color={handleGlobalButtonColor()}
                                onClick={() => handleButtonClick(isLocal, !isGlobal)}
                            >
                                {t('galleryMediaTab_global')}
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Button
                                variant="contained"
                                color={'error'}
                                onClick={handleDeleteClick}
                            >
                                {t('galleryMediaTab_delete')}
                            </Button>
                            <IconButton onClick={onClose} color="error">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default ScheduleDialog;
